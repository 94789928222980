import { mainApi } from "../../../../app/services/main"
const feeReceiveApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        getReceivedFees: builder.query<any, any>({
            query: (data) => ({
                url: `/fees/receive`,
                method: "GET",
                params: data
            }),
            providesTags: ['feeReceive'],
        }),
        receiveFee: builder.mutation<any, any>({
            query: (feeData) => ({
                url: '/fees/receive',
                method: 'POST',
                body: feeData,
            }),
            invalidatesTags: ['feeReceive']
        }),
        editReceiveFee: builder.mutation<any, any>({
            query: (feeData) => ({
                url: '/fees/receive',
                method: 'PUT',
                body: feeData,
            }),
            invalidatesTags: ['feeReceive']
        }),
        deleteReceivedFee: builder.mutation<any, any>({
            query: (feeData) => ({
                url: '/fees/receive/delete',
                method: 'POST',
                body: feeData,
            }),
            invalidatesTags: ['feeReceive']
        })
    }),
    overrideExisting: false,
})

export const {
    useGetReceivedFeesQuery,
    useReceiveFeeMutation,
    useEditReceiveFeeMutation,
    useDeleteReceivedFeeMutation
  
} = feeReceiveApi
