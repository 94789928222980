import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

const authProviderSlice = createSlice({
    name: 'authProvider',
    initialState: { isLoggedIn: false },
    reducers: {
        setIsLoggedInValue: (state, actions: { payload: boolean }) => {
            state.isLoggedIn = actions.payload;
        }
    }
});

export const { setIsLoggedInValue } = authProviderSlice.actions;

export const selectIsLoggedInValue = (state: RootState) => state.authProvider.isLoggedIn;

export default authProviderSlice.reducer;

