import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from "react-redux";
import { setEditClassDialog } from "../classSlice";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Controller, useForm } from "react-hook-form";
import { useEditClassMutation } from "../classes";
import { Messages } from "primereact/messages";
import { useEffect, useRef } from "react";
import { showToast } from "../../../../components/toast/toastSlice";

const EditClassDialog = () => {
    const dispatch = useDispatch();
    const visible = useSelector((state: any) => state.classSlice.editClassDialog);
    const selectedClass = useSelector((state:any) => state.classSlice.selectedClass);
    const [editClass, { isLoading, data, error, isError }] = useEditClassMutation();
    const msgs = useRef<Messages>(null);

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            <span className="font-bold white-space-nowrap">Edit Class</span>
        </div>
    );
    
    const defaultValues = {
        classname: '',
        level: '',
        details: ''
    };

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        setValue,
        watch
    } = useForm({ defaultValues });

    useEffect(() => {
        setValue("classname" , selectedClass.classname);
        setValue("level" , selectedClass.classlevel);
        setValue("details" , selectedClass.classdetails);
        return () => {
        }
      }, [selectedClass, visible]);

    useEffect(() => {
        clearMessages();
        return () => {
        }
    }, [watch('classname'), watch('details'), watch('level')])

    const onSubmit = async (data: any) => {
        const payload = {
            "id": selectedClass.id,
            "classname": data.classname,
            "classlevel": data.level,
            "classdetails": data.details
        }        
        editClass(payload);
    };

    const getFormErrorMessage = (
        name: 'classname' | 'level' | 'details') => {
        return errors[name] && <small className="p-error">{errors[name]?.message}</small>
    };

    const clearMessages = () => {
        msgs.current?.clear();
    };

    const showMessage = (severity: "error" | "success" | "info" | "warn" | "secondary" | "contrast", summary: string, detail: string) => {
        clearMessages();
        msgs.current?.show([
            { sticky: true, severity: `${severity}`, summary: `${summary}`, detail: `${detail}` },
        ]);
    }

    useEffect(() => {

        if (error && !isLoading && 'data' in error) {
            const mess: any = error.data
            clearMessages()
            showMessage('error', 'Failed!', mess.message);
            return
        }

        if (!isLoading && data && 'message' in data) {
            dispatch(showToast({ message: data.message, show: true, severity: 'success', summary: 'Success' }))
            reset();
            dispatch(setEditClassDialog(false));
            return
        }
        return () => {
        }
    }, [isLoading, data]);


    return (
        <Dialog visible={visible} modal header={headerElement} style={{ width: '50rem' }} onHide={() => { dispatch(setEditClassDialog(false)) }}>
            <div>
                <Messages ref={msgs} />
                <div className='ms-4'>
                    <p className='text-md text-gray-900  '>Enter new class details below to edit class.</p>
                </div>
                <div className='flex flex-col overflow-hidden bg-white p-3 rounded-md shadow-lg '>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='formgrid grid '>
                            <div className='field col-12'>
                                <Controller
                                    name="classname"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Class Name is required.'
                                        },
                                        minLength: {
                                            value: 3,
                                            message: "Class Name should be atleast 3 letters or numbers"
                                        }
                                    }}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.classname })}></label>
                                            <span className="p-float-label">
                                                <InputText
                                                    pt={{
                                                        root: { className: 'w-full' }
                                                    }}
                                                    id={field.name}
                                                    value={field.value}
                                                    className={classNames({ 'p-invalid': fieldState.error })}
                                                    onChange={(e) => field.onChange(e.target.value)}
                                                />
                                                <label htmlFor={field.name}>Class Name</label>
                                            </span>
                                            {getFormErrorMessage(field.name)}
                                        </>
                                    )}
                                />
                            </div>
                            
                            <div className='field col-12'>
                                <Controller
                                    name="level"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Class Level is required.'
                                        },
                                        minLength: {
                                            value: 3,
                                            message: "Class Level should be atleast 3 letters"
                                        }
                                    }}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.level })}></label>
                                            <span className="p-float-label">
                                                <InputText
                                                    pt={{
                                                        root: { className: 'w-full' }
                                                    }}
                                                    id={field.name}
                                                    value={field.value}
                                                    className={classNames({ 'p-invalid': fieldState.error })}
                                                    onChange={(e) => field.onChange(e.target.value)}
                                                />
                                                <label htmlFor={field.name}>Current Class Level</label>
                                            </span>
                                            {getFormErrorMessage(field.name)}
                                        </>
                                    )}
                                />
                            </div>
                            <div className='field col-12'>
                                <Controller
                                    name="details"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Class details is required.'
                                        },
                                        minLength: {
                                            value: 3,
                                            message: "Class details should be atleast 3 letters"
                                        }
                                    }}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.details })}></label>
                                            <span className="p-float-label">
                                                <InputText
                                                    pt={{
                                                        root: { className: 'w-full' }
                                                    }}
                                                    id={field.name}
                                                    value={field.value}
                                                    className={classNames({ 'p-invalid': fieldState.error })}
                                                    onChange={(e) => field.onChange(e.target.value)}
                                                />
                                                <label htmlFor={field.name}>Other Name(s)</label>
                                            </span>
                                            {getFormErrorMessage(field.name)}
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                        <div className='w-full flex justify-content-center'>
                            <Button label="Edit Class" type="submit" className='w-6 ' loading={isLoading} />
                        </div>
                    </form>
                </div>
            </div>
        </Dialog>
    )
}

export default EditClassDialog