import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../features/auth/authSlice";
import { jwtDecode } from "jwt-decode";

const ValidateToken = () => {
  try {
    let accessToken = useSelector(selectCurrentUser);
    const decoded = jwtDecode(accessToken);
    const seconds = Math.round(Date.now() / 1000);
    return !(decoded.exp === undefined || decoded.exp < seconds)
  } catch (error) {
    return false
  }
}

const AuthProviders = () => {
  const location = useLocation();

  return (
    ValidateToken() ? <Outlet /> : <Navigate to="/login" state={{ from: location }} replace />
  )
}

export default AuthProviders