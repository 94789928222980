import { Avatar } from "primereact/avatar";
import { FormatCurrency, getBalanceSeverity } from "../../../../utilities";
import { Button } from "primereact/button";

const StudentViewDetails = (props: { selectedStudent: any, fees: boolean }) => {

    const selectedStudent = props.selectedStudent;   

    return (
        <div>
            <div className="flex flex-wrap ">
                <div className="col-12 card">
                    <div className="flex flex-column justify-content-center align-items-center bg-white rounded-md shadow-md p-3 ">
                        <Avatar icon="pi pi-user" size="xlarge" style={{ backgroundColor: '#2196F3', color: '#ffffff' }} shape="circle" />
                        <span className='mt-2 text-lg font-bold uppercase'>{`${selectedStudent.firstname} ${selectedStudent.lastname} ${selectedStudent.othername}`}</span>
                        <span className='mt-2 text-md uppercase'>{`${selectedStudent.student_id}`}</span>
                        { props.fees ? <Button className='mt-2' label={FormatCurrency(selectedStudent.balance)} severity={getBalanceSeverity(selectedStudent.balance)} outlined /> : <></>}
                    </div>
                </div>
                <div className="w-full">
                    <div className="flex flex-column align-items-center w-full rounded-md shadow-md">
                        <div className='flex flex-wrap lg:flex-nowrap lg:gap-2 w-full ' >

                            <div className="col-12 lg:col-6 card h-30rem">
                                <div className='w-full rounded-tl-lg rounded-tr-lg h-8 flex align-items-center' style={{ color: 'var(--highlight-text-color)' }}>
                                    <span className='text-xl font-bold'>Student Details</span>
                                </div>
                                <div className='flex flex-row flex-wrap justify-content-between w-full mt-4'>
                                    <p className='text-md text-gray-800'>Current Level</p>
                                    <p className='font-bold'>{selectedStudent.class_level}</p>
                                </div>

                                <div className='flex flex-row flex-wrap justify-content-between w-full mt-2'>
                                    <p className='text-md text-gray-800'>Current Class</p>
                                    <p className='font-bold'>{selectedStudent.class_name}</p>
                                </div>

                                <div className='flex flex-row flex-wrap justify-content-between w-full mt-2'>
                                    <p className='text-md text-gray-800'>Current Route</p>
                                    <p className='font-bold'>{selectedStudent.route_name}</p>
                                </div>
                                <div className='flex flex-row flex-wrap justify-content-between w-full mt-2'>
                                    <p className='text-md text-gray-800'>Date of Birth</p>
                                    <p className='font-bold'>{selectedStudent.date_of_birth}</p>
                                </div>
                                <div className='flex flex-row flex-wrap justify-content-between w-full  mt-2'>
                                    <p className='text-md text-gray-800'>Gender</p>
                                    {selectedStudent.gender == 1 ? <p className='font-bold'>Male</p> : <>Female</>}
                                </div>
                                <div className='flex flex-row flex-wrap justify-content-between w-full mt-2'>
                                    <p className='text-md text-gray-800'>Details</p>
                                    <p className='font-bold'>{selectedStudent.details}</p>
                                </div>
                                <div className='flex flex-row flex-wrap justify-content-between w-full mt-2'>
                                    <p className='text-md text-gray-800'>Date of Admission</p>
                                    <p className='font-bold'>{selectedStudent.date_of_admission}</p>
                                </div>
                                <div className='flex flex-row flex-wrap justify-content-between w-full mt-2'>
                                    <p className='text-md text-gray-800'>Date of Release</p>
                                    <p className='font-bold'>{selectedStudent.date_of_release}</p>
                                </div>
                            </div>
                            <div className="col-12 lg:col-6 card h-30rem">
                                <div className='w-full rounded-tl-lg rounded-tr-lg h-8 flex align-items-center' style={{ color: 'var(--highlight-text-color)' }}>
                                    <span className='text-xl font-bold'>Guardian(s) Details</span>
                                </div>

                                <div className='w-full rounded-tl-lg rounded-tr-lg h-8 flex align-items-center mt-3' >
                                    <span className='text-md'>Primary Guardian</span>
                                </div>

                                <div className='flex flex-row flex-wrap justify-content-between w-full mt-2'>
                                    <p className='text-md text-gray-800'>Relationship</p>
                                    <p className='font-bold'>{selectedStudent.guardian_relationship}</p>
                                </div>

                                <div className='flex flex-row flex-wrap justify-content-between w-full mt-2'>
                                    <p className='text-md text-gray-800'>Name</p>
                                    <p className='font-bold'>{selectedStudent.guardian_name}</p>
                                </div>

                                <div className='flex flex-row flex-wrap justify-content-between w-full mt-2'>
                                    <p className='text-md text-gray-800'>Contact</p>
                                    <p className='font-bold'>{selectedStudent.guardian_phone}</p>
                                </div>

                                <div className='w-full rounded-tl-lg rounded-tr-lg flex align-items-center' >
                                    <span className='text-md'>Secondary Guardian</span>
                                </div>

                                <div className='flex flex-row flex-wrap justify-content-between w-full mt-2'>
                                    <p className='text-md text-gray-800'>Relationship</p>
                                    <p className='font-bold'>{selectedStudent.guardian_relationship_1}</p>
                                </div>
                                <div className='flex flex-row flex-wrap justify-content-between w-full  mt-2'>
                                    <p className='text-md text-gray-800'>Name</p>
                                    <p className='font-bold'>{selectedStudent.guardian_name_1}</p>
                                </div>
                                <div className='flex flex-row flex-wrap justify-content-between w-full mt-2'>
                                    <p className='text-md text-gray-800'>Contact</p>
                                    <p className='font-bold'>{selectedStudent.guardian_phone_1}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StudentViewDetails