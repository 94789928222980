const Topcard = (props: {number: string, label: string, icon: string, color: string}) => {
    return (
        <div className="col-12 lg:col-6 xl:col-3">
            <div className="card mb-0">
                <div className="flex justify-content-between">
                    <div>
                        <span className="block text-500 font-medium mb-3">{props.label}</span>
                        <div className="text-900 font-medium text-xl">{props.number}</div>
                    </div>
                    <div className={`flex align-items-center justify-content-center border-round bg-${props.color}-100`} style={{ width: '2.5rem', height: '2.5rem' }}>
                        <i className={`text-xl ${props.icon} text-${props.color}-500`} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Topcard