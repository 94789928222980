import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import { classNames } from "primereact/utils";
import { useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../../../components/toast/toastSlice";
import { setNewChargedDialog } from "../../feemanagement/charged/feechargeslice";
import StudentsDropdown from "../../feemanagement/received/components/studentsDropdown";
import { setAddToRouteDialog } from "../transportslice";
import { useAddStudentToRouteMutation } from "../viewroute";

const AddtoRouteDialog = () => {
  const dispatch = useDispatch();
  const selectedRoute = useSelector((state: any) => state.transportSlice.selectedRoute);
  const visible = useSelector((state: any) => state.transportSlice.addStudentToRouteDialog);
  const [addStudent, { isLoading, data, error, isError }] = useAddStudentToRouteMutation();
  const msgs = useRef<Messages>(null);

  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold white-space-nowrap">Add student to route</span>
    </div>
  );

  const defaultValues = {
    student: {},
    details: ''
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    watch
  } = useForm({ defaultValues });

  useEffect(() => {
    clearMessages();
    return () => {
    }
  }, [watch('student')]);

  useEffect(() => {
    setValue('details', selectedRoute.route_name)
  
    return () => {
      
    }
  }, [selectedRoute, visible]);

  const onSubmit = async (values: any) => {

    const payload = {
      route_name: selectedRoute.route_name,
      student_id: values.student.student_id
    }
    addStudent(payload);
  };

  const getFormErrorMessage = (
    name: 'student' | 'details'
  ) => {
    return errors[name] && <small className="p-error">{errors[name]?.message}</small>
  };

  const clearMessages = () => {
    msgs.current?.clear();
  };

  const showMessage = (severity: "error" | "success" | "info" | "warn" | "secondary" | "contrast", summary: string, detail: string) => {
    clearMessages();
    msgs.current?.show([
      { sticky: true, severity: `${severity}`, summary: `${summary}`, detail: `${detail}` },
    ]);
  }

  useEffect(() => {

    if (error && !isLoading && 'data' in error) {
      const mess: any = error.data
      clearMessages()
      showMessage('error', 'Failed!', mess.message);
      return
    }

    if (!isLoading && data && 'message' in data) {
      dispatch(showToast({ message: data.message, show: true, severity: 'success', summary: 'Success' }))
      dispatch(setAddToRouteDialog(false));
      reset();
      return
    }
    return () => {
    }
  }, [isLoading, data]);

  return (
    <Dialog visible={visible} modal header={headerElement} style={{ width: '40rem' }} onHide={() => { dispatch(setAddToRouteDialog(false)); reset() }}>
      <div className="w-full">
        <Messages ref={msgs} />
        <div className='ms-4'>
          <p className='text-md text-gray-900  '>Select student to add to route.</p>
        </div>
        <div className='flex flex-col overflow-hidden bg-white p-3 rounded-md shadow-lg w-full'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='formgrid grid w-full'>
              <div className='field col-12'>
                <Controller
                  name="details"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Route name are required.'
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      <label htmlFor={field.name} className={classNames({ 'p-error': errors.details })}></label>
                      <span className="p-float-label">
                        <InputText
                          disabled={true}
                          pt={{
                            root: { className: 'w-full' }
                          }}
                          id={field.name}
                          value={field.value}
                          className={classNames({ 'p-invalid': fieldState.error })}
                          onChange={(e) => field.onChange(e.target.value)}
                        />
                        <label htmlFor={field.name}>Route Name</label>
                      </span>
                      {getFormErrorMessage(field.name)}
                    </>
                  )}
                />
              </div>
              <StudentsDropdown control={control} errors={errors} getFormError={getFormErrorMessage} setValue={setValue} type={2} />

            </div>
            <div className='w-full flex justify-content-center'>
              <Button label="Add Student" type="submit" className='w-6 ' loading={isLoading} />
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  )
}

export default AddtoRouteDialog