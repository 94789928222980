import { useEffect } from "react";
import { useGetUserDetailsQuery } from "../../app/services/auth"
import Topcard from "./components/topcard";
import { useDispatch, useSelector } from "react-redux";
import { setUserDetails } from "../auth/authSlice";

const Topbar = () => {
    const { data, isLoading } = useGetUserDetailsQuery(null);
    const dispatch = useDispatch();
    const authdetails = useSelector((state:any) => state.auth);    

    useEffect(() => {        
        if(data && 'message' in data){           
            dispatch(setUserDetails({
                firstname: data?.message?.firstname,
                lastname: data?.message?.lastname,
                school_name: data?.message?.school_name,
                students: data?.message?.students_no,
                teachers: data?.message?.teachers,
                classes: data?.message?.classes,
                smsbalance: data?.message?.balance,
                logo_url: data?.message?.logo_url,
                school_phone: data?.message?.school_phone,
                school_email: data?.message?.school_email,
                school_motto: data?.message?.school_motto,
                po_box: data?.message?.po_box
            }));                                    
        }       
      return () => {
      }
    }, [data, isLoading]);
    
    return (
        <>
            <Topcard label="Students" icon="pi pi-fw pi-graduation-cap" color="blue" number={authdetails.students} />
            <Topcard label="Teachers" icon="pi pi-fw pi-clipboard" color="orange" number={authdetails.teachers} />
            <Topcard label="Classes" icon="pi pi-fw pi-building-columns" color="cyan" number={authdetails.classes} />
            <Topcard label="Sms Credits" icon="pi pi-comment" color="purple" number={authdetails.smsbalance} />
        </>
    )
}

export default Topbar