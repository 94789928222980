import { createSlice } from '@reduxjs/toolkit';

const transportSlice = createSlice({
    name: 'transportSlice',
    initialState: { selectedRoute: {}, newRouteDialog: false, editRouteDialog: false, addStudentToRouteDialog: false },
    reducers: {
        setRoute: (state, actions: { payload: any }) => {
            state.selectedRoute = actions.payload;
        },
        setNewRouteDialog: (state, actions: { payload: boolean }) => {
            state.newRouteDialog = actions.payload;
        },
        setEditRouteDialog: (state, actions: { payload: boolean }) => {
            state.editRouteDialog = actions.payload;
        },
        setAddToRouteDialog: (state, actions: { payload: boolean }) => {
            state.addStudentToRouteDialog = actions.payload;
        }
    },
});

export const {
    setRoute,
    setNewRouteDialog,
    setEditRouteDialog,
    setAddToRouteDialog
} = transportSlice.actions;

export default transportSlice.reducer;

