import { DataTable } from "primereact/datatable";
import { useGetSmsSentQuery } from "../sms"
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { FilterMatchMode } from "primereact/api";

import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';

const Sentsmstable = () => {

    const { data, isLoading } = useGetSmsSentQuery(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },

    });

    const createdBodyTemplate = (rowData: { createdAt: string | number | Date; }) => {
        var date = new Date(rowData.createdAt).toLocaleDateString('en-GB');
        return (
            <div>
                {`${date}`}
            </div>
        )
    }
    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let _filters = { ...filters };

        // @ts-ignore
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">Sent Sms's</h4>
            <IconField iconPosition="left">
                <InputIcon className="pi pi-search"> </InputIcon>
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
            </IconField>
        </div>
    );

    return (
        <>
            <DataTable
                size={"small"}
                value={data?.data}
                dataKey="_id"
                paginator
                showGridlines
                loading={isLoading}
                rows={10}
                globalFilter={globalFilterValue}
                globalFilterFields={['createdAt', 'phone_number', 'tag', 'tag_id', 'message', 'num', 'detail']}
                header={header}
            >
                <Column field="createdAt" body={createdBodyTemplate} header="Date" sortable style={{ minWidth: '6rem' }} />
                <Column field="phone_number" header="Number" sortable style={{ minWidth: '8rem' }} />
                <Column field="tag" header="Tag" sortable style={{ minWidth: '6rem' }} />
                <Column field="tag_id" header="Detail" sortable style={{ minWidth: '8rem' }} />
                <Column field="message" header="Message" sortable style={{ minWidth: '12rem' }} />
                <Column field="num" header="Sms" sortable style={{ minWidth: '4rem' }} />
            </DataTable>
        </>
    )
}

export default Sentsmstable