import ChargedFeeTable from './components/chargedfeetable'
import EditChargeDialog from './components/editchargedialog'
import NewChargeDialog from './components/newchargedialog'

const ChargefeePage = () => {
  return (
    <>
      <NewChargeDialog />
      <EditChargeDialog />
      <ChargedFeeTable />
    </>
  )
}

export default ChargefeePage