import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { useEffect } from 'react';
import { useDeleteRouteMutation } from '../transport';

const DeleteRoutePopUp = (props: { rowData: any, msgs: any }) => {

    const [deleteFee, { data, isLoading, error, isError }] = useDeleteRouteMutation();

    const accept = () => {
        const payload = {
            "id": props.rowData._id,
        }
        deleteFee(payload)
    }

    const reject = () => {
    }

    const deleteDialogShow = (event: any) => {
        confirmPopup({
            target: event?.currentTarget,
            message: 'Do you want to delete route?',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept,
            reject
        });
    };
    const showMessage = (severity: "error" | "success" | "info" | "warn" | "secondary" | "contrast", summary: string, detail: string) => {
        clearMessages();
        props.msgs.current?.show([
            { sticky: true, severity: `${severity}`, summary: `${summary}`, detail: `${detail}` },
        ]);
    }

    const clearMessages = () => {
        props.msgs.current?.clear();
    };


    useEffect(() => {
        if (error && !isLoading && 'data' in error) {
            const mess: any = error.data
            clearMessages()
            showMessage('error', 'Failed!', mess.message);
            return
        }

        if (!isLoading && data && 'message' in data) {
            clearMessages()
            showMessage('success', 'Success!', data.message);
            return
        }

        return () => {

        }
    }, [data, isLoading])

    return (
        <div>
            <ConfirmPopup />

            <div
                onClick={(event) => { deleteDialogShow(event) }}
                className='trash border border-red-500 rounded-md w-2rem h-2rem flex justify-content-center align-items-center'
                style={{ fontSize: '2rem', cursor: 'pointer' }}
            >
                <i className="pi pi-trash" style={{ color: 'red' }}></i>
            </div>
        </div>
    )
}

export default DeleteRoutePopUp