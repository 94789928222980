import { createSlice } from '@reduxjs/toolkit';

const studentsSlice = createSlice({
  name: 'studentSlice',
  initialState: { selectedStudent: {}, selectedStudentFinance: {} },
  reducers: {
    setSelectedStudent: (state, actions: { payload: any }) => {
      state.selectedStudent = actions.payload;
    },
    setSelectedStudentFinance: (state, actions: { payload: any }) => {
      state.selectedStudentFinance = actions.payload;
    },
    // setSmsStudentDialog: ( state, actions : {payload : boolean}) => {     
    //   state.student = actions.payload;
    // },
    // showToastDialog: ( state, actions : {payload : {message: string, show: boolean, severity: string}}) => {     
    //   state.toast = actions.payload;
    // },
    // setFilteredStudents: ( state, actions : {payload : any}) => {
    //   state.filteredStudents = actions.payload;
    // }
  },
});

export const {
  setSelectedStudent,
  setSelectedStudentFinance
} = studentsSlice.actions;

export default studentsSlice.reducer;

