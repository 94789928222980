import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from "react-redux";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Controller, useForm } from "react-hook-form";
import { Messages } from "primereact/messages";
import { useEffect, useRef } from "react";
import { showToast } from "../../../../components/toast/toastSlice";
import { addToFeeBreakdown, clearFeeBreakdown, setEditReceivedDialog } from "./feereceiveslice";
import { Dropdown } from "primereact/dropdown";
import StudentsDropdown from "./components/studentsDropdown";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";
import { useEditReceiveFeeMutation } from "./feereceive";
import { setDate } from "../../../../utilities";
import FeeBreakDown from "./components/feebreakdown";

const EditReceivePage = () => {
    const dispatch = useDispatch();
    const selectedReceived = useSelector((state: any) => state.feeReceiveSlice.selectedReceived);
    const feePaymentArray = useSelector((state: any) => state.feeReceiveSlice.feeBreakdown);

    const [editReceiveFee, { isLoading, data, error, isError }] = useEditReceiveFeeMutation();
    const msgs = useRef<Messages>(null);

    const mode = [
        { name: 'Bank', code: 'Bank' },
        { name: 'Mpesa', code: 'Mpesa' },
        { name: 'Cash', code: 'Cash' },
    ];

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            <span className="font-bold white-space-nowrap">Edit Fee Received</span>
        </div>
    );
    const defaultValues = {
        student: '',
        mode: {},
        reference: '',
        dop: new Date(),
        amount: null,
        details: '',
        account: ''
    };

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        setValue,
        watch
    } = useForm({ defaultValues });

    useEffect(() => {
        if ('date_received' in selectedReceived) {
            setValue("reference", selectedReceived.reference_no)
            setValue("details", selectedReceived.details)
            setValue("account", selectedReceived.account)
            setValue("amount", selectedReceived.amount)
            setValue("dop", setDate(selectedReceived.date_received));

            dispatch(clearFeeBreakdown());
            selectedReceived.payment_data.map((data: any) => {                
                dispatch(addToFeeBreakdown(data))
            });

            const modeVal = mode.find((mod) => mod.code == selectedReceived.mode);
            if (modeVal) {
                setValue("mode", modeVal)
            }
        }
        return () => {

        }
    }, [, selectedReceived])

    useEffect(() => {
        clearMessages();
        return () => {
        }
    }, [watch('student'), watch('mode'), watch('reference')])


    const onSubmit = async (data: any) => {
        const dop = new Date(data.dop).toLocaleDateString('en-GB');
        let amount = 0;

        feePaymentArray.map((fees: any) => {
            amount += fees.amount
        });
        const payload = {
            "receipt_no": selectedReceived.receipt_no,
            "student_id": data.student.student_id,
            "mode": data.mode.code,
            "reference_no": data.reference,
            "date_received": dop,
            amount,
            "details": data.details,
            "account": data.account,
            payment_data: feePaymentArray
        }
        editReceiveFee(payload);
    };

    const getFormErrorMessage = (
        name: 'student' | 'mode' | 'reference' | 'dop' | 'amount' | 'details' | 'account'
    ) => {
        return errors[name] && <small className="p-error">{errors[name]?.message}</small>
    };

    const clearMessages = () => {
        msgs.current?.clear();
    };

    const showMessage = (severity: "error" | "success" | "info" | "warn" | "secondary" | "contrast", summary: string, detail: string) => {
        clearMessages();
        msgs.current?.show([
            { sticky: true, severity: `${severity}`, summary: `${summary}`, detail: `${detail}` },
        ]);
    }

    useEffect(() => {

        if (error && !isLoading && 'data' in error) {
            const mess: any = error.data
            clearMessages()
            showMessage('error', 'Failed!', mess.message);
            return
        }

        if (!isLoading && data && 'message' in data) {
            dispatch(showToast({ message: data.message, show: true, severity: 'success', summary: 'Success' }))
            reset();
            dispatch(clearFeeBreakdown());
            return
        }
        return () => {
        }
    }, [isLoading, data]);

    return (
        <div>
            <Messages ref={msgs} />
            <div className='flex flex-column overflow-hidden bg-white p-3 rounded-md shadow-lg card'>
                <div className='ms-5'>
                    <p className='text-xl font-semibold text-gray-900'>Edit Record Received Fee</p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='formgrid grid '>
                        <StudentsDropdown control={control} errors={errors} getFormError={getFormErrorMessage} setValue={setValue} type={1} />
                        <div className='field col-12 md:col-6'>
                            <Controller
                                name="mode"
                                control={control}
                                rules={{ required: 'Mode required.' }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <label htmlFor={field.name} className={classNames({ 'p-error': errors.mode })}></label>
                                        <span className="flex ">
                                            <Dropdown
                                                pt={{
                                                    root: { className: 'w-full' }
                                                }}
                                                id={field.name}
                                                value={field.value}
                                                className={classNames({ 'p-invalid': fieldState.error })}
                                                onChange={(e) => field.onChange(e.target.value)}
                                                options={mode}
                                                optionLabel="name"
                                                placeholder="Select Payment Mode"
                                            />

                                        </span>
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>
                        <div className='field col-12 md:col-6'>
                            <Controller
                                name="account"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Account number are required.'
                                    }
                                }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <label htmlFor={field.name} className={classNames({ 'p-error': errors.account })}></label>
                                        <span className="p-float-label">
                                            <InputText
                                                pt={{
                                                    root: { className: 'w-full' }
                                                }}
                                                id={field.name}
                                                value={field.value}

                                                onChange={(e) => field.onChange(e.target.value)}
                                            />
                                            <label htmlFor={field.name}>Account Number</label>
                                        </span>
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>
                        <div className='field col-12 md:col-6'>
                            <Controller
                                name="reference"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Reference number are required.'
                                    }
                                }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <label htmlFor={field.name} className={classNames({ 'p-error': errors.reference })}></label>
                                        <span className="p-float-label">
                                            <InputText
                                                pt={{
                                                    root: { className: 'w-full' }
                                                }}
                                                id={field.name}
                                                value={field.value}

                                                onChange={(e) => field.onChange(e.target.value)}
                                            />
                                            <label htmlFor={field.name}>Reference Number</label>
                                        </span>
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>
                        <div className='field col-12 md:col-6'>
                            <Controller
                                name="dop"
                                control={control}
                                rules={{ required: 'Date of Payment is required.' }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <label htmlFor={field.name} className={classNames({ 'p-error': errors.dop })}></label>
                                        <span className="p-float-label">
                                            <Calendar
                                                dateFormat="dd/mm/yy"
                                                pt={{ root: { className: 'w-full' } }}
                                                id={field.name}
                                                value={field.value}
                                                onChange={(e) => { field.onChange(e.target.value) }}
                                                showIcon
                                            />
                                            <label htmlFor={field.name}>Date of Payment</label>
                                        </span>
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>
                        <div className='field col-12'>
                            <Controller
                                name="details"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Payment details are required.'
                                    },
                                    minLength: {
                                        value: 3,
                                        message: "Payment details should be atleast 3 letters or numbers"
                                    }
                                }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <label htmlFor={field.name} className={classNames({ 'p-error': errors.details })}></label>
                                        <span className="p-float-label">
                                            <InputText
                                                pt={{
                                                    root: { className: 'w-full' }
                                                }}
                                                id={field.name}
                                                value={field.value}
                                                className={classNames({ 'p-invalid': fieldState.error })}
                                                onChange={(e) => field.onChange(e.target.value)}
                                            />
                                            <label htmlFor={field.name}>Payment Details</label>
                                        </span>
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>
                        <FeeBreakDown />
                    </div>
                    <div className='w-full flex justify-content-center'>
                        <Button label="Edit Fee Record" type="submit" className='w-6 ' loading={isLoading} />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default EditReceivePage