import { mainApi } from "./main";

export interface UserAuth {
  roles: Array<[number]>
  firstname: string,
  email: string
}

export interface LoginResponse {
  refreshToken: string;
  accessToken: string;
  roles: Array<[number]>
  result: object
  token: string
}

export interface LoginRequest {
  email: string
  pwd: string
}

export type LogoutResponse = {
  tag: string
  message: string
}

export type UserDetailsResponse = {
  email: string
  firstname: string
  lastname: string
  phone_number: string
  team: string
  team_id: string
  roles: Array<[number]>
}

const authApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: (credentials) => ({
        url: '/auth',
        method: 'POST',
        body: credentials,
      }),
      invalidatesTags: ['users', 'userDetails', 'auth', 'students', 'feeCheck', 'sms', 'classes', 'feeReceive', 'feeCharge','feeGroups', 'routes', 'viewRoute', 'feeType']
    }),
    forgotPassword: builder.mutation<any, any>({
      query: (body) => ({
        url: '/auth/forgot',
        method: 'POST',
        body,
      }),
    }),
    setNewPassword: builder.mutation<any, any>({
      query: (body) => ({
        url: '/auth/reset',
        method: 'POST',
        body,
      }),
    }),
    logout: builder.mutation<LogoutResponse, null>({      
      query: () => ({
        url: '/logout',
        method: 'GET',
        params: {}
      }),
      invalidatesTags: ['users', 'userDetails', 'auth', 'students', 'feeCheck', 'sms', 'classes', 'feeReceive', 'feeCharge','feeGroups', 'routes', 'viewRoute', 'feeType']
    }),
    getUserDetails: builder.query({
      query: () => `/data/setup`,
      providesTags: ['userDetails']
    }),
  }),
  overrideExisting: false,
})

export const { 
  useLoginMutation, 
  useForgotPasswordMutation,
  useSetNewPasswordMutation,
  useLogoutMutation, 
  useGetUserDetailsQuery
 } = authApi

