import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { classNames } from "primereact/utils";
import { useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useForgotPasswordMutation, useLoginMutation } from "../../app/services/auth";
import { setIsLoggedInValue } from "../../providers/authproviderSlice";
import { setCredentials } from "./authSlice";
import { Messages } from "primereact/messages";

const ForgotPasswordPage = () => {
    const [recoverPassword, { isLoading }] = useForgotPasswordMutation();
    const [show, setshow] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const msgs = useRef<Messages>(null);

    const clearMessages = () => {
        msgs.current?.clear();
    };

    const showMessage = (severity: "error" | "info" | "success" | "warn" | "secondary" | "contrast", summary: string, detail: string) => {
        msgs.current?.show([
            { severity, summary, detail, sticky: true },
        ]);
    }
    const defaultValues = {
        email: '',
    };

    const {
        control,
        formState: { errors },
        handleSubmit
    } = useForm({ defaultValues });

    const onSubmit = async (values: any) => {
        clearMessages();
        try {
            const result = await recoverPassword({ email: values.email });
            if ('data' in result) {
                showMessage('info', 'Succesful!', `An email has been sent to ${values.email}. Click on the link provided to create a new password`)
            } else {
                showMessage('error', 'Failed!', `An error has occurred. Try again later!`)
            }
        } catch (error: any) {
            showMessage('error', 'Failed!', `An error has occurred. Try again later!`)
        }
    };

    const getFormErrorMessage = (name: string) => {
        if (name == 'email') {
            return errors['email'] && <small className="p-error">{errors['email'].message}</small>
        }
    };
    return (
        <div className="flex justify-content-center align-items-center h-screen w-screen surface-ground ">
            {/* <AppConfig /> */}
            <div className="flex flex-column align-items-center justify-content-center w-full px-2">
                <div
                    style={{
                        borderRadius: '56px',
                        padding: '0.3rem',
                        background: 'linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)'
                    }}
                    className="w-full sm:w-fit "
                >
                    <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: '53px' }}>
                        <div className="text-center mb-5">
                            <div className="text-900 text-3xl font-medium mb-3">Forgot Password!</div>
                            <span className="text-600 font-medium">Enter email to reset password</span>
                        </div>
                        <div>
                            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                                <Messages ref={msgs} />
                                <div className=" mt-2">
                                    <span className="">
                                        <Controller
                                            name="email"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Email is required.'
                                                },
                                                pattern: {
                                                    value: /\S+@\S+\.\S+/,
                                                    message: "Email format -> xyz@mail.com "
                                                }
                                            }}
                                            render={({ field, fieldState }) => (
                                                <>
                                                    <span className="p-input-icon-left">
                                                        <label htmlFor="email1" className="block text-900 text-xl font-medium mb-2">
                                                            Email
                                                        </label>
                                                        <InputText
                                                            pt={{
                                                                root: { classNames: 'w-full' }
                                                            }}
                                                            id={field.name}
                                                            type="text"
                                                            value={field.value}
                                                            onChange={(e) => field.onChange(e.target.value)}
                                                            placeholder="Email address"
                                                            className={classNames("w-full md:w-30rem mb-2", { 'p-invalid': fieldState.error })}
                                                            style={{ padding: '1rem' }}
                                                        />
                                                    </span>
                                                    {getFormErrorMessage(field.name)}
                                                </>
                                            )}
                                        />
                                    </span>
                                </div>
                                <div className="flex align-items-center justify-content-end">
                                    <Link
                                        to={"/login"}
                                    >
                                        <a className="font-medium no-underline ml-2 text-right cursor-pointer" style={{ color: 'var(--primary-color)' }}>
                                            Back to Login
                                        </a>
                                    </Link>
                                </div>
                                <Button disabled={isLoading} label="Reset Password" aria-label="Submit" className="mt-5" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPasswordPage