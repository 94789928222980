import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { useRef, useState } from "react";
import { useGetUsersQuery } from "../user";
import React from "react";
import { Tag } from "primereact/tag";
import { Chip } from "primereact/chip";
import CanAccess from "../../../components/auth/canaccess";
import DeleteUserPopUp from "./deleteuserpopup";
import { Messages } from "primereact/messages";
import { useDispatch } from "react-redux";
import { setEditUserDialog, setNewUserDialog, setSelectedUser } from "../userSlice";
import { Button } from "primereact/button";

const UsersTable = () => {

    const dispatch = useDispatch();
    const msgs = useRef<Messages>(null);
    const { data, isLoading } = useGetUsersQuery(null);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let _filters = { ...filters };        

        // @ts-ignore
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search"> </InputIcon>
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                </IconField>
                <CanAccess resource="accounts" action="create">
                    <Button onClick={() => { dispatch(setNewUserDialog(true)) }} className='w-full sm:w-fit' type="button" size="small" icon="pi pi-plus" label="New User" outlined />
                </CanAccess>
            </div>
        );
    };

    const actionBodyTemplate = (rowData: { index: React.Key | null | undefined; }) => {
        return (
            <CanAccess resource="students" action="delete">
                <React.Fragment>
                    <div className='flex justify-content-center'>
                        <div
                            onClick={() => { dispatch(setEditUserDialog(true)); dispatch(setSelectedUser(rowData)) }}
                            className='edit border border-blue-700 rounded-md w-2rem h-2rem flex justify-content-center align-items-center me-1'
                            data-pr-tooltip="view"
                            data-pr-position="top"
                            style={{ fontSize: '2rem', cursor: 'pointer' }}
                        >
                            <i className="pi pi-pencil" style={{ color: 'blue' }}></i>
                        </div>
                        <DeleteUserPopUp rowData={rowData} msgs={msgs} />
                    </div>
                </React.Fragment>
            </CanAccess>
        )
    };

    const nameBodyTemplate = (rowData: any) => {
        return `${rowData.firstname} ${rowData.lastname} `
    };

    const statusBodyTemplate = (rowData: any) => {
        return (
            <div className="flex justify-content-center">
                {
                    rowData.status ? <Tag severity="success" value={`Active`} rounded></Tag> : <Tag severity="danger" value={`Suspended`} rounded></Tag>
                }
            </div>
        )
    }

    const rolesBodyTemplate = (rowData: any) => {

        let tag = 'Admin';

        if(rowData.roles[0] == 4819){
            tag = 'Secretary'
        }
        else if (rowData.roles[0] == 6783){
            tag = 'Accountant';
        }
        return (
            <div className="flex justify-content-center">
                <Chip label={tag} />
            </div>
        )
    }

    const header = renderHeader();
    return (
        <>
        <Messages ref={msgs}/>
            <div className="col-12 xl:col-12">
                <div >
                    <h5>Received Fees </h5>
                    <DataTable
                        value={data}
                        loading={isLoading}
                        size={"small"}
                        paginator
                        showGridlines
                        rows={10}
                        globalFilter={globalFilterValue}
                        dataKey="_id"
                        globalFilterFields={['first_name', 'last_name', 'email', 'roles', 'status']}
                        header={header}
                        emptyMessage="No users found."
                    >
                        <Column field="first_name" header="Name" body={nameBodyTemplate} style={{ minWidth: '8rem' }} />
                        <Column field="email" header="Email" style={{ minWidth: '8rem' }} />
                        <Column field="roles" header="Roles" body={rolesBodyTemplate} style={{ minWidth: '8rem' }} />
                        <Column field="status" header="Status" body={statusBodyTemplate} style={{ minWidth: '8rem' }} />
                        <Column field="action" header="Action" body={actionBodyTemplate} style={{ minWidth: '6rem' }} />
                    </DataTable>
                </div>
            </div>
        </>
    )
}

export default UsersTable