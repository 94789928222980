import AdminOverview from "./adminoverview";


const OverviewPage = () => {

    return GetOverview();
};

const GetOverview = () => {
    let currentUser = 7231;

    switch (currentUser) {
        case 7231:
            return (<AdminOverview />)
        case 6421:
            return (<>HeadTeacher</>)
        case 5811:
            return (<>Accountant</>)
        case 5201:
            return (<>Secretary</>)
        default:
            return (<></>)
    }
}

export default OverviewPage;
