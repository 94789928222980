import { createSlice, current } from '@reduxjs/toolkit';

const feeReceiveSlice = createSlice({
    name: 'feeReceiveSlice',
    initialState: { selectedReceived: {}, newReceiveDialog: false, editReceiveDialog: false, feeBreakdown: Array() },
    reducers: {
        setReceivedFee: (state, actions: { payload: any }) => {
            state.selectedReceived = actions.payload;
        },
        setNewReceivedDialog: (state, actions: { payload: boolean }) => {
            state.newReceiveDialog = actions.payload;
        },
        setEditReceivedDialog: (state, actions: { payload: boolean }) => {
            state.editReceiveDialog = actions.payload;
        },
        addToFeeBreakdown: (state, actions: { payload: any }) => {
            state.feeBreakdown.push(actions.payload)
        },
        removeFromFeeBreakdown: (state, actions: { payload: number }) => {            
            state.feeBreakdown.splice(actions.payload, 1);
        },
        clearFeeBreakdown: (state) => {
            state.feeBreakdown = Array();
        }
    },
});

export const { setReceivedFee,
    setNewReceivedDialog,
    setEditReceivedDialog,
    addToFeeBreakdown,
    removeFromFeeBreakdown,
    clearFeeBreakdown
} = feeReceiveSlice.actions;

export default feeReceiveSlice.reducer;

