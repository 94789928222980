import { Messages } from 'primereact/messages';
import { useGetRoutesQuery } from '../transport'
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import CanAccess from '../../../../components/auth/canaccess';
import { setEditRouteDialog, setNewRouteDialog, setRoute } from '../transportslice';
import DeleteRoutePopUp from './deleteroutepopup';
import { Link } from 'react-router-dom';

const TransportTable = () => {
    const { data, isLoading } = useGetRoutesQuery(null);
    const dispatch = useDispatch();
    const msgs = useRef<Messages>(null);

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const setSelectedRoute = (rowData: any) => {
        dispatch(setRoute(rowData));
        dispatch(setEditRouteDialog(true));
    }

    const actionBodyTemplate = (rowData: { index: React.Key | null | undefined; }) => {
        return (
            <React.Fragment>
                <div className='flex justify-content-center'>
                    <CanAccess resource="students" action="view">
                        <Link
                            onClick={() => { dispatch(setRoute(rowData)); }}
                            className='me-1'
                            to={`view`}
                            key={rowData.index}
                        >
                            <div
                                className='edit border border-green-700 rounded-md w-2rem h-2rem flex justify-center align-items-center'
                                data-pr-tooltip="view"
                                data-pr-position="top"
                                style={{ fontSize: '2rem', cursor: 'pointer' }}
                            >
                                <i className="pi pi-eye" style={{ color: 'green' }}></i>
                            </div>
                        </Link>
                    </CanAccess>
                    <CanAccess resource="students" action="edit">
                        <div
                            onClick={() => { setSelectedRoute(rowData) }}
                            className='edit border border-blue-700 rounded-md w-2rem h-2rem flex justify-content-center align-items-center me-1'
                            data-pr-tooltip="view"
                            data-pr-position="top"
                            style={{ fontSize: '2rem', cursor: 'pointer' }}
                        >
                            <i className="pi pi-pencil" style={{ color: 'blue' }}></i>
                        </div>
                    </CanAccess>
                    <CanAccess resource="students" action="delete">
                        <DeleteRoutePopUp rowData={rowData} msgs={msgs} />
                    </CanAccess>
                </div>
            </React.Fragment>
        )
    };

    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let _filters = { ...filters };

        // @ts-ignore
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search"> </InputIcon>
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                </IconField>
                <CanAccess resource="accounts" action="create">
                    <Button onClick={() => { dispatch(setNewRouteDialog(true)) }} className='w-full sm:w-fit' type="button" size="small" icon="pi pi-plus" label="New Route" outlined />
                </CanAccess>
            </div>
        );
    };

    const header = renderHeader();

    return (
        <>
            <div className="col-12 xl:col-12">
                <Messages ref={msgs} />
                <div className="card">
                    <h5>Routes Overview</h5>
                    <DataTable
                        value={data?.data}
                        size={"small"}
                        paginator
                        showGridlines
                        rows={10}
                        loading={isLoading}
                        globalFilter={globalFilterValue}
                        dataKey="route_name"
                        globalFilterFields={['route_name', 'details', 'students']}
                        header={header}
                        emptyMessage="No routes found."
                    >
                        <Column field="route_name" header="Route Name" style={{ minWidth: '2rem' }} />
                        <Column field="details" header="Details" style={{ minWidth: '8rem' }} />
                        <Column field="students" header="Students No" style={{ minWidth: '8rem' }} />
                        <Column field="action" header="Action" body={actionBodyTemplate} style={{ minWidth: '6rem' }} />
                    </DataTable>
                </div>
            </div>
        </>
    )
}

export default TransportTable