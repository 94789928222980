import { mainApi } from "../../../app/services/main";

const classesApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        getClasses: builder.query<any, any>({
            query: (data) => ({
                url: `/classes`,
                method: "GET",
                params: data
            }),
            providesTags: ['classes'],
        }),
        newClass: builder.mutation<any, any>({
            query: (classData) => ({
                url: '/classes',
                method: 'POST',
                body: classData,
            }),
            invalidatesTags: ['classes', 'feeGroups', 'feeCheck']
        }),
        editClass: builder.mutation<any, any>({
            query: (classData) => ({
                url: '/classes',
                method: 'PUT',
                body: classData,
            }),
            invalidatesTags: ['classes', 'feeGroups', 'feeCheck']
        }),
        deleteClass: builder.mutation<any, any>({
            query: (classData) => ({
                url: 'classes/delete',
                method: 'POST',
                body: classData,
            }),
            invalidatesTags: ['classes', 'feeGroups', 'feeCheck']
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetClassesQuery,
    useNewClassMutation,
    useEditClassMutation,
    useDeleteClassMutation
} = classesApi
