import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from "react-redux";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Controller, useForm } from "react-hook-form";
import { Messages } from "primereact/messages";
import { useEffect, useRef, useState } from "react";
import { showToast } from "../../../../../components/toast/toastSlice";
import { addToFeeBreakdown, setNewReceivedDialog } from "../feereceiveslice";
import { InputNumber } from "primereact/inputnumber";
import { useEditReceiveFeeMutation } from "../feereceive";
import FeeTypeDropDown from "../../charged/components/feetypedropdown";
import { Tag } from "primereact/tag";

const NewPaymentDialog = () => {
    const dispatch = useDispatch();
    const visible = useSelector((state: any) => state.feeReceiveSlice.newReceiveDialog);
    const [feePaymentArray, setFeePaymentArray] = useState(Array())

    const msgs = useRef<Messages>(null);

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            <span className="font-bold white-space-nowrap">Add fee payment </span>
        </div>
    );
    const defaultValues = {
        fee_type: '',
        amount: null,
    };

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        setValue,
        getValues
    } = useForm({ defaultValues });

    const onSubmit = async (data: any) => {

    };

    const clearMessages = () => {
        msgs.current?.clear();
    };

    const showMessage = (severity: "error" | "success" | "info" | "warn" | "secondary" | "contrast", summary: string, detail: string) => {
        clearMessages();
        msgs.current?.show([
            { sticky: true, severity: `${severity}`, summary: `${summary}`, detail: `${detail}` },
        ]);
    }

    const AddFeePayment = () => {

        const fee_type = getValues('fee_type');
        const amount = getValues('amount');

        if (!fee_type || !amount) {
            showMessage('error', 'Failed!', "Fill all fields");
            return;
        }
        const payload = {
            fee_type,
            amount,
        }
        feePaymentArray.push(payload);
        setFeePaymentArray(feePaymentArray);
        dispatch(addToFeeBreakdown(payload))
        dispatch(setNewReceivedDialog(false))
    }

    const getFormErrorMessage = (
        name: 'fee_type' | 'amount'
    ) => {
        return errors[name] && <small className="p-error">{errors[name]?.message}</small>
    };

    return (
        <Dialog visible={visible} modal header={headerElement} style={{ width: '40rem' }} onHide={() => { dispatch(setNewReceivedDialog(false)); reset() }}>
            <div>
                <Messages ref={msgs} />
                <div className='ms-4'>
                    <p className='text-md text-gray-900  '>Enter payment details </p>
                </div>
                <div className='flex flex-col overflow-hidden  p-3 rounded-md shadow-lg w-full bg-white'>
                    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
                        <FeeTypeDropDown control={control} errors={errors} getFormError={getFormErrorMessage} setValue={setValue} type={2} />
                        <div className='formgrid grid w-full'>
                            <div className='field col-12'>
                                <Controller
                                    name="amount"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Payment amount are required.'
                                        }
                                    }}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.amount })}></label>
                                            <span className="p-float-label">
                                                <InputNumber
                                                    pt={{ root: { className: 'w-full' } }}
                                                    id={field.name}
                                                    inputRef={field.ref}
                                                    value={field.value}
                                                    onBlur={field.onBlur}
                                                    onValueChange={(e) => field.onChange(e)}
                                                    useGrouping={true}
                                                    inputClassName={classNames({ 'p-invalid': fieldState.error })}
                                                />


                                                <label htmlFor={field.name}>Amount</label>
                                            </span>
                                            {getFormErrorMessage(field.name)}
                                        </>
                                    )}
                                />
                            </div>

                        </div>
                        <div className='w-full flex justify-content-center'>
                            <Tag className="h-2.5rem p-3 w-12rem" onClick={() => { AddFeePayment() }} value="Add payment details"></Tag>
                        </div>
                    </form>
                </div>
            </div>
        </Dialog>
    )
}

export default NewPaymentDialog