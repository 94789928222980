import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Messages } from "primereact/messages"
import { Toast } from "primereact/toast"
import { classNames } from "primereact/utils";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import { useSendSmsMutation } from "../sms";
import StudentFeeTable from "../../../../components/tables/studentfeetable";

const StudentSmsPage = () => {
  const [sendCustomSms, { isLoading, data }] = useSendSmsMutation();

  const msgs = useRef<Messages>(null);
  const toast = useRef<Toast>(null);
  const [actualMessage, setActualMessage] = useState('This is an example of the first message to be sent');
  const [messageExample, setMessageExample] = useState('This is an example of the first message to be sent')
  const [characters, setCharacters] = useState(0);
  const [messagesNo, setMessagesNo] = useState(1);
  const [messageDialog, setMessageDialog] = useState(false);
  const smsCredit = useSelector((state: any) => state.auth.smsbalance)


  const [students, setStudents] = useState(0);
  const [totalParents, setTotalParents] = useState(0);
  const [totalSms, setTotalSms] = useState(0);
  const filteredStudents = useSelector((state: any) => state.smsDialogs.filteredStudents);

  const defaultValues = {
    message: '',
    a_message: ''
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    register,
    setValue,
    getValues,
    resetField
  } = useForm({ defaultValues });


  const clearMessages = () => {
    msgs?.current?.clear();
  };

  function getMessage(message: string, balance: number, name: any, student_id: any) {

    message = message.replace("SETBALANCE", Math.abs(balance) + '');
    message = message.replace("SETNAME", name);
    message = message.replace("SETSTUDENTID", student_id);
    return message;
  }

  function getMessageNo(message: string | any[]) {
    return Math.ceil(message.length / 145);
  }

  const getParentsNo = () => {
    let parents = 0;

    filteredStudents.map((student: any) => {
      const parent = Number(student.parents);
      parents += parent;

    });
    return parents;
  }
  useEffect(() => {
    const message = watch("message");
    setActualMessage(message);

    if (filteredStudents.length == 0)
      return
    const balance = filteredStudents[0].balance;
    const name = filteredStudents[0].name;
    const student_id = filteredStudents[0].student_id;
    const myMesssage = getMessage(message, balance, name, student_id);
    setCharacters(myMesssage.length);
    setMessagesNo(getMessageNo(myMesssage));
    setMessageExample(myMesssage);
    return () => {
      clearMessages();
    }
  }, [watch("message")]);

  useEffect(() => {
    let students = filteredStudents.length;
    let totalParents = getParentsNo();
    let totalSms = totalParents * messagesNo;

    console.log();
    
    setStudents(students);
    setTotalParents(totalParents);
    setTotalSms(totalSms);

    return () => {

    }
  }, [filteredStudents, messagesNo])

  const getMessagePayload = () => {
    let totalMessageNo = 0;

    let data: { message: string; number: any; num: number; tag: string; tag_id: any; }[] = [];

    filteredStudents.map((student: { balance: any; name: any; student_id: any; guardian_phone: string; guardian_phone_1: string; }) => {
      const balance = student.balance;
      const name = student.name;
      const student_id = student.student_id;
      const myMesssage = getMessage(actualMessage, balance, name, student_id);

      let number_1 = student.guardian_phone.split(" ").join("");
      number_1 = number_1.split("-").join("");
      number_1 = number_1.split("(").join("");
      number_1 = number_1.split(")").join("").trim();

      let number_2 = student.guardian_phone_1.split(" ").join("");
      number_2 = number_2.split("-").join("");
      number_2 = number_2.split("(").join("");
      number_2 = number_2.split(")").join("").trim();

      let messageNo = getMessageNo(actualMessage);
      totalMessageNo += messagesNo;

      const data1 = {
        "message": myMesssage,
        "number": number_1,
        "num": messageNo,
        "tag": "Student",
        "tag_id": student.student_id
      }

      const data2 = {
        "message": myMesssage,
        "number": number_2,
        "num": messageNo,
        "tag": "Student",
        "tag_id": student.student_id
      }
      data.push(data1, data2);
    });

    const valueData = {
      data,
      values: totalMessageNo
    }

    return valueData;
  }

  const getFormErrorMessage = (name: string) => {
    if (name == 'message') {
      return errors[name] && <small className="p-error">{errors[name]?.message}</small>
    }
  };
  

  const nowSendSems = async () => {
    const messagePayload = getMessagePayload();
    if (smsCredit < messagePayload.values) {
      toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Insufficient sms credits', life: 3000 });
      return;
    }
    await sendCustomSms(messagePayload);
    hideDialog();
    resetField("message");
    resetField("a_message");
   
  }

  const onSubmit = async (values: any) => {
    setMessageDialog(true);
  };

  const hideDialog = () => {
    setMessageDialog(false);
  };

  useEffect(() => {

    if (!isLoading && data) {
      if (data && 'message' in data) {
        toast.current?.show({ severity: 'success', summary: 'Success', detail: data.message, life: 3000 });

      }

    }
    return () => {
    }
  }, [isLoading, data])

  return (
    <>
      <div className="w-full">
        <Dialog
          visible={messageDialog}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header="Send Student Batches"
          modal
          className="p-fluid"
          onHide={hideDialog}
        >
          <div className='font-semibold'>Sms Details </div>
          <div className='grid'>
            <div className='col-12 grid mt-0'>
              <div className='col-4 flex flex-col'>
                <span className='text-center'>Sms Credits</span>
                <span className='text-center'>{`${smsCredit}`}</span>
              </div>
              <div className='col-4 '>

              </div>
              <div className='col-4 flex flex-col'>
                <span className='text-center'>Total sms</span>
                <span className='text-center'>{`${totalSms}`}</span>
              </div>
            </div>

            <div className='col-12 grid'>
              <div className='col-4 flex flex-col'>
                <span className='text-center'>Sms Size</span>
                <span className='text-center'>{`${messagesNo}`}</span>
              </div>
              <div className='col-4 flex flex-col'>
                <span className='text-center'>No of Students</span>
                <span className='text-center'>{`${students}`}</span>
              </div>
              <div className='col-4 flex flex-col'>
                <span className='text-center'>Parents</span>
                <span className='text-center'>{`${totalParents}`}</span>
              </div>
            </div>

            <div className='flex flex-col'>
              <span className='font-semibold mb-2'>Sms Example</span>
              <span>{`${messageExample}`}</span>
            </div>

            <div className='w-full flex justify-center mt-3'>
              <Button label="Confirm" onClick={nowSendSems} className='w-1/2 ' />
            </div>
          </div>

        </Dialog>
        <Toast ref={toast} />
        <Messages ref={msgs} />
        <div className='mt-4 ms-4'>
          <p></p>
          <p className='text-lg text-gray-900 my-2 '>Select students to send messages to parents or guardians</p>
          <p className='text-sm text-gray-800 my-2 '>Keys:</p>
          <p className='text-md text-gray-700 my-2 '>SETSTUDENTID (sets the Student ID)</p>
          <p className='text-md text-gray-700 my-2 '>SETNAME (sets the Student Name)</p>
          <p className='text-md text-gray-700 my-2 '>SETBALANCE (sets the Student Balance)</p>
        </div>
        <div className='flex flex-col overflow-hidden bg-white p-3 rounded-md shadow-lg'>
          <div className="w-full">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='formgrid grid '>

                <div className='field col-12 md:col-6'>
                  <Controller
                    name="message"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Message is required.'
                      }
                    }}
                    render={({ field, fieldState }) => (
                      <>
                        <label htmlFor={field.name} className={classNames({ 'p-error': errors.message })}></label>
                        <span className="p-float-label">
                          <InputTextarea
                            pt={{
                              root: { className: 'w-full' }
                            }}
                            id={field.name}
                            autoResize={true}
                            {...field}
                            rows={4}
                            cols={50}
                            className={classNames({ 'p-invalid': fieldState.error })}
                          />
                          <label htmlFor={field.name}>Message</label>
                        </span>
                        {getFormErrorMessage(field.name)}
                      </>
                    )}
                  />
                  <div className='flex justify-content-between'>
                    <p className='text-xs'>{`${characters} characters`}</p>
                    <p className='text-xs'>{`${messagesNo} message`}(s)</p>
                  </div>
                </div>
                <div className='field col-12 md:col-6'>
                  <Controller
                    name="a_message"
                    control={control}

                    render={({ field, fieldState }) => (
                      <>
                        <label htmlFor={field.name} className={classNames({ 'p-error': errors.a_message })}></label>
                        <span className="p-float-label">
                          <InputTextarea
                            pt={{
                              root: { className: 'w-full' }
                            }}
                            id={field.name}
                            autoResize={true}
                            disabled
                            rows={4}
                            cols={50}
                            value={messageExample}
                          />
                          <label htmlFor={field.name}>Message Example</label>
                        </span>
                        {getFormErrorMessage(field.name)}
                      </>
                    )}
                  />
                </div>
              </div>
              <div className='my-3'>
                <StudentFeeTable />
              </div>
              <div className='w-full flex justify-content-center'>
                <Button disabled={false} label="Send Message(s)" type="submit" className='w-6 ' />
              </div>
            </form>
          </div>
        </div>
      </div>

    </>
  )
}

export default StudentSmsPage