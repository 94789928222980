import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'screenSize',
  initialState: { screenSize: 0 },
  reducers: {
    setScreenSize: (state, actions) => {      
      state.screenSize = actions.payload;
    }
  },
});

export const { setScreenSize } = slice.actions

export default slice.reducer