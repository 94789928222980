import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';

export type AuthState = {
  roles: Array<[number]>
  accessToken: string,
  refreshToken: string,
  firstname: string,
  lastname: string,
  school_name: string,
  students: any,
  classes: any,
  teachers: any,
  smsbalance: any,
  logo_url: string
  school_email: string,
  school_phone: string,
  school_motto: string,
  po_box: string
}

const initialState = {
  roles: [], accessToken: '', refreshToken: '', firstname: '', lastname: '',
  school_name: '', students: '0', classes: '0', teachers: '0', smsbalance: '0',
  logo_url: 'none', school_email: '', school_phone: '', school_motto: '', po_box: ''
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState as AuthState,
  reducers: {
    setCredentials: (
      state,
      {
        payload: { roles, accessToken, refreshToken },
      }: PayloadAction<{ roles: Array<[number]>; accessToken: string, refreshToken: string }>,
    ) => {
      state.roles = roles;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
    },
    setUserDetails: (
      state,
      {
        payload: { firstname, lastname, school_name, smsbalance, students, classes, teachers, logo_url, school_email, school_phone, school_motto, po_box},
      }: PayloadAction<{
        firstname: string, lastname: string, school_name: string, students: any,
        classes: any, teachers: any, smsbalance: any, logo_url: string, school_email: string,
        school_phone: string, school_motto: string, po_box: string
      }>) => {
      state.firstname = firstname;
      state.lastname = lastname;
      state.school_name = school_name;
      state.students = students;
      state.classes = classes;
      state.teachers = teachers;
      state.smsbalance = smsbalance;
      state.logo_url = logo_url;
      state.school_email = school_email;
      state.school_phone = school_phone;
      state.school_motto = school_motto;
      state.po_box = po_box;
    },
    removeCredentials: (state) => {
      state.accessToken = '';
      state.roles = [];
    }
  },
})

export const { setCredentials, setUserDetails, removeCredentials } = authSlice.actions

export default authSlice.reducer

export const selectCurrentUser = (state: RootState) => state.auth.accessToken
