import { mainApi } from "../../../app/services/main";

const viewRouteApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getOneRoute: builder.query<any, any>({
        query: (routeData) => ({
            url: '/routes/one',
            method: 'GET',
            params: routeData,
        }),
        providesTags: ['routes'],
    }),
    addStudentToRoute: builder.mutation<any, any>({
        query: (studentData) => ({
            url: '/routes/students',
            method: 'POST',
            body: studentData,
        }),
        invalidatesTags: ['routes', 'students', 'viewRoute', 'feeCheck']
    }),
    removeStudentFromRoute: builder.mutation<any, any>({
        query: (studentData) => ({
            url: '/routes/students/remove',
            method: 'POST',
            body: studentData,
        }),
        invalidatesTags: ['routes', 'students', 'viewRoute', 'feeCheck']
    })
  }),
  overrideExisting: false,
})

export const { 
    useGetOneRouteQuery,
    useAddStudentToRouteMutation,
    useRemoveStudentFromRouteMutation  
} = viewRouteApi

