import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import CanAccess from "../../../../../components/auth/canaccess";
import DeleteReceivedPopUp from "../../received/components/deletereceivedpopup";
import { useGetChargedFeesQuery } from "../feecharge";
import { setChargedFee, setEditChargedDialog, setNewChargedDialog } from "../feechargeslice";
import DeleteChargedPopUp from "./deletechargedpopup";

const ChargedFeeTable = () => {
    const { isLoading, data } = useGetChargedFeesQuery(null);
    const dispatch = useDispatch();
    const msgs = useRef<Messages>(null);

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const setCharged = (rowData: any) => {
        dispatch(setChargedFee(rowData));
        dispatch(setEditChargedDialog(true));
    }

    const actionBodyTemplate = (rowData: { index: React.Key | null | undefined; }) => {
        return (
            <React.Fragment>
                <div className='flex justify-content-center'>
                    <CanAccess resource="students" action="edit">
                        <div
                            onClick={() => { setCharged(rowData) }}
                            className='edit border border-blue-700 rounded-md w-2rem h-2rem flex justify-content-center align-items-center me-1'
                            data-pr-tooltip="view"
                            data-pr-position="top"
                            style={{ fontSize: '2rem', cursor: 'pointer' }}
                        >
                            <i className="pi pi-pencil" style={{ color: 'blue' }}></i>
                        </div>
                    </CanAccess>
                    <CanAccess resource="students" action="delete">
                        <DeleteChargedPopUp rowData={rowData} msgs={msgs} />
                    </CanAccess>
                </div>
            </React.Fragment>
        )
    };

    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let _filters = { ...filters };

        // @ts-ignore
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search"> </InputIcon>
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                </IconField>
                <CanAccess resource="accounts" action="create">
                    <Button onClick={() => { dispatch(setNewChargedDialog(true)) }} className='w-full sm:w-fit' type="button" size="small" icon="pi pi-plus" label="Charge Fee" outlined />
                </CanAccess>
            </div>
        );
    };

    const header = renderHeader();

    return (
        <>
            <div className="col-12 xl:col-12">
                <Messages ref={msgs} />
                <div className="card">
                    <h5>Charged Fees Overview</h5>
                    <DataTable
                        value={data?.data}
                        size={"small"}
                        paginator
                        showGridlines
                        rows={10}
                        loading={isLoading}
                        globalFilter={globalFilterValue}
                        dataKey="_id"
                        globalFilterFields={['fee_name', 'amount', 'date_of_charge', 'tag', 'tag_id', 'fee_details']}
                        header={header}
                        emptyMessage="No charged fees found."
                    >
                        <Column field="fee_name" header="Fee Name" style={{ minWidth: '2rem' }} />
                        <Column field="tag_id" header="Tag ID" style={{ minWidth: '8rem' }} />
                        <Column field="tag" header="Tag Detail" style={{ minWidth: '8rem' }} />
                        <Column field="fee_details" header="Details" style={{ minWidth: '8rem' }} />
                        <Column field="amount" header="Amount" style={{ minWidth: '8rem' }} />
                        <Column field="date_of_charge" header="Date Charged" style={{ minWidth: '8rem' }} />
                        <Column field="action" header="Action" body={actionBodyTemplate} style={{ minWidth: '6rem' }} />
                    </DataTable>
                </div>
            </div>
        </>
    )
}

export default ChargedFeeTable