import { mainApi } from "../../../app/services/main";

const transportApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getRoutes: builder.query<any, any>({
        query: () => ({
            url: `/routes`,
            method: "GET",
        }),
        providesTags: ['routes'],
    }),
    newRoute: builder.mutation<any, any>({
        query: (routeData) => ({
            url: '/routes',
            method: 'POST',
            body: routeData,
        }),
        invalidatesTags: ['routes', 'students']
    }),
    editRoute: builder.mutation<any, any>({
        query: (routeData) => ({
            url: '/routes',
            method: 'PUT',
            body: routeData,
        }),
        invalidatesTags: ['routes', 'students']
    }),
    deleteRoute: builder.mutation<any, any>({
        query: (routeData) => ({
            url: '/routes/delete',
            method: 'POST',
            body: routeData,
        }),
        invalidatesTags: ['routes', 'students']
    }),    
  }),
  overrideExisting: false,
})

export const { 
    useGetRoutesQuery,
    useNewRouteMutation, 
    useEditRouteMutation, 
    useDeleteRouteMutation
} = transportApi

