import { Avatar } from "primereact/avatar"
import { Button } from "primereact/button"
import { useSelector } from "react-redux"

const ProfilePage = () => {
    const authdetails = useSelector((state: any) => state.auth);

    return (
        <div className="flex overflow-hidden card flex-column md:flex-row">
           
            <div className="flex flex-grow-1 flex-column mx-3">
                <span className="text-xl text-black font-bold mb-3" > {`${authdetails.school_name}`} </span>
                <div className="mb-2">
                    <span className="text-black font-semibold">Name </span>
                    <span className=""> - </span>
                    <span>{`${authdetails.school_name}`}</span>
                </div>
                <div>
                    <span className="text-black font-semibold">ROLE </span>
                    <span> - </span>
                    <span> </span>
                </div>

            </div>
            <div className="flex flex-column gap-2 flex-none justify-content-center align-items-center text-gray-300">
                <Button severity="secondary" outlined text raised label="Top up Sms" />
                <Button severity="secondary" outlined text raised label="Change password" />
            </div>
        </div>
    )
}

export default ProfilePage