import { createSlice } from '@reduxjs/toolkit';

const toastSlice = createSlice({
    name: 'toastSet',
    initialState: { message: '', show: false, severity: 'success', summary: 'info' },
    reducers: {
        showToast: (state, actions: {
            payload: {
                message: string,
                show: boolean,
                severity: 'info' | 'success' | 'warning' | 'danger' | 'contrast' | 'secondary',
                summary: string
            }
        }) => {
            state.message = actions.payload.message;
            state.show = actions.payload.show;
            state.severity = actions.payload.severity;
            state.summary = actions.payload.summary;
        }
    },
});

export const { showToast } = toastSlice.actions;

export default toastSlice.reducer;

