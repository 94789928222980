import { mainApi } from "../../../app/services/main"

const feeApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        getFeeGroups: builder.query<any, any>({
            query: (data) => ({
                url: `/fees/check`,
                method: "GET",
                params: data
            }),
            providesTags: ['feeGroups'],
        }),
    
    }),
    overrideExisting: false,
})

export const {
    useGetFeeGroupsQuery  
} = feeApi
