import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import { classNames } from "primereact/utils";
import { useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../../components/toast/toastSlice";
import { setNewUserDialog } from "../userSlice";
import { Dropdown } from "primereact/dropdown";
import { useNewUsersMutation } from "../user";

const NewUserDialogs = () => {
  const dispatch = useDispatch();
  const visible = useSelector((state: any) => state.userSlice.newUserDialog);
  const [newUser, { isLoading, data, error, isError }] = useNewUsersMutation();
  const msgs = useRef<Messages>(null);

  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold white-space-nowrap">Add new user</span>
    </div>
  );

  const defaultValues = {
    firstname: '',
    lastname: '',
    email: '',
    roles: '',
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch
  } = useForm({ defaultValues });

  useEffect(() => {
    clearMessages();
    return () => {
    }
  }, [watch('firstname'), watch('lastname'), watch('email'), watch('roles')])

  const roles = [
    { name: "Admin", role: [7231] },
    { name: 'Secretary', role: [4819] },
    { name: 'Accountant', role: [6783] },
    { name: 'Teacher', role: [4783] }
  ];

  const onSubmit = async (data: any) => {
    const payload = {
      "firstname": data.firstname,
      "lastname": data.lastname,
      "roles": data.roles.role,
      "email": data.email
    }
    newUser(payload);
  };

  const getFormErrorMessage = (
    name: 'firstname' | 'lastname' | 'email' | 'roles') => {
    return errors[name] && <small className="p-error">{errors[name]?.message}</small>
  };

  const clearMessages = () => {
    msgs.current?.clear();
  };

  const showMessage = (severity: "error" | "success" | "info" | "warn" | "secondary" | "contrast", summary: string, detail: string) => {
    clearMessages();
    msgs.current?.show([
      { sticky: true, severity: `${severity}`, summary: `${summary}`, detail: `${detail}` },
    ]);
  }

  useEffect(() => {

    if (error && !isLoading && 'data' in error) {
      const mess: any = error.data
      clearMessages()
      showMessage('error', 'Failed!', mess.message);
      return
    }

    if (!isLoading && data && 'message' in data) {
      dispatch(showToast({ message: data.message, show: true, severity: 'success', summary: 'Success' }))
      reset();
      dispatch(setNewUserDialog(false));
      return
    }
    return () => {
    }
  }, [isLoading, data]);

  return (
    <Dialog visible={visible} modal header={headerElement} style={{ width: '50rem' }} onHide={() => { dispatch(setNewUserDialog(false)) }}>
      <div>
        <Messages ref={msgs} />
        <div className='ms-4'>
          <p className='text-md text-gray-900  '>Enter user details below to add the user.</p>
        </div>
        <div className='flex flex-col overflow-hidden bg-white p-3 rounded-md shadow-lg '>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='formgrid grid '>
              <div className='field col-12'>
                <Controller
                  name="firstname"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'First Name is required.'
                    },
                    minLength: {
                      value: 3,
                      message: "First Name should be atleast 3 letters or numbers"
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      <label htmlFor={field.name} className={classNames({ 'p-error': errors.firstname })}></label>
                      <span className="p-float-label">
                        <InputText
                          pt={{
                            root: { className: 'w-full' }
                          }}
                          id={field.name}
                          value={field.value}
                          className={classNames({ 'p-invalid': fieldState.error })}
                          onChange={(e) => field.onChange(e.target.value)}
                        />
                        <label htmlFor={field.name}>First Name</label>
                      </span>
                      {getFormErrorMessage(field.name)}
                    </>
                  )}
                />
              </div>
              <div className='field col-12'>
                <Controller
                  name="lastname"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Last name is required.'
                    },
                    minLength: {
                      value: 3,
                      message: "Last name should be atleast 3 letters"
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      <label htmlFor={field.name} className={classNames({ 'p-error': errors.lastname })}></label>
                      <span className="p-float-label">
                        <InputText
                          pt={{
                            root: { className: 'w-full' }
                          }}
                          id={field.name}
                          value={field.value}
                          className={classNames({ 'p-invalid': fieldState.error })}
                          onChange={(e) => field.onChange(e.target.value)}
                        />
                        <label htmlFor={field.name}>Last name</label>
                      </span>
                      {getFormErrorMessage(field.name)}
                    </>
                  )}
                />
              </div>
              <div className='field col-12'>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Email is required.'
                    },
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Email format -> xyz@mail.com "
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      <label htmlFor={field.name} className={classNames({ 'p-error': errors.email })}></label>
                      <span className="p-float-label">
                        <InputText
                          pt={{
                            root: { className: 'w-full' }
                          }}
                          id={field.name}
                          value={field.value}
                          className={classNames({ 'p-invalid': fieldState.error })}
                          onChange={(e) => field.onChange(e.target.value)}
                        />
                        <label htmlFor={field.name}>Email</label>
                      </span>
                      {getFormErrorMessage(field.name)}
                    </>
                  )}
                />
              </div>
              <div className='field col-12'>
                <Controller
                  name="roles"
                  control={control}
                  rules={{ required: 'Role is required.' }}
                  render={({ field, fieldState }) => (
                    <>
                      <label htmlFor={field.name} className={classNames({ 'p-error': errors.roles })}></label>
                      <span className="flex ">
                        <Dropdown
                          pt={{
                            input: { className: 'w-full' }
                          }}
                          id={field.name}
                          value={field.value}
                          className={classNames('w-full', { 'p-invalid': fieldState.error })}
                          onChange={(e) => field.onChange(e.target.value)}
                          options={roles}
                          optionLabel="name"
                          placeholder="Select role"
                        />
                      </span>
                      {getFormErrorMessage(field.name)}
                    </>
                  )}
                />
              </div>
            </div>
            <div className='w-full flex justify-content-center'>
              <Button label="Add User" type="submit" className='w-6 ' loading={isLoading} />
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  )
}

export default NewUserDialogs