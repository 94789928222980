import { useDispatch, useSelector } from "react-redux";
import { setCustomDialog } from "../smslice";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Messages } from "primereact/messages";
import { useSendSmsMutation } from "../sms";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Chips } from "primereact/chips";
import { classNames } from "primereact/utils";
import { InputTextarea } from "primereact/inputtextarea";
import { Badge } from "primereact/badge";
import { showToast } from "../../../../components/toast/toastSlice";
import { useGetUserDetailsQuery } from "../../../../app/services/auth";

const CustomSmsForm = () => {
    const [sendCustomSms, { isLoading, data, error, isError }] = useSendSmsMutation();
    const dispatch = useDispatch();

    const smsCredit = useSelector((state: any) => state.auth.smsbalance);
    const [characters, setCharacters] = useState(0);
    const [messagesNo, setMessagesNo] = useState(1);
    const [recepients, setRecepients] = useState(0);
    const [totalSms, setTotalSms] = useState(0);

    const msgs = useRef<Messages>(null);
    const toast = useRef<Toast>(null);

    useEffect(() => {

        if (isError && error && !isLoading && 'data' in error) {
            const mess: any = error.data
            clearMessages()
            showMessage('error', 'Failed!', mess.message);
            return
        }

        if (!isLoading && data && 'message' in data) {
            dispatch(showToast({ message: data.message, show: true, severity: 'success', summary: 'Success' }));
            dispatch(setCustomDialog(false));

            return
        }
        return () => {
        }
    }, [isLoading, data])

    useEffect(() => {
        setTotalSms(messagesNo * recepients);
        return () => {
        }
    }, [messagesNo, recepients]);


    const defaultValues = {
        numbers: [],
        message: '',
    };
    const clearMessages = () => {
        msgs.current?.clear();
    };

    const {
        control,
        formState: { errors },
        handleSubmit,
        getValues,
        watch,
        reset
    } = useForm({ defaultValues });
    
    useEffect(() => {
        const message = watch("message");
        setCharacters(message.length);
        setMessagesNo(getMessageNo(message));
        const numbers = watch("numbers");
        setRecepients(numbers.length)

        clearMessages();
        return () => {
        }
    }, [watch("message"), watch("numbers")]);


    const showMessage = (severity: "error" | "success" | "info" | "warn", summary: string, detail: string) => {
        msgs.current?.show([
            { sticky: true, severity: `${severity}`, summary: `${summary}`, detail: `${detail}` },
        ]);
    }

    function getMessageNo(message: string | any[]) {
        return Math.ceil(message.length / 145);
    }

    const validateNumber = (numbers: any) => {

        const data = numbers.map((number: string) => {
            let numberArray = number.split("");
            let first3 = numberArray[0] + numberArray[1] + numberArray[2]

            if (number.length == 12 && first3 == '254') {

                if (numberArray[3] == '7' || numberArray[3] == '1') {
                    return true;
                }


                return false;
            }
            return false;
        });

        if (data.includes(false)) {
            showMessage('error', 'Invalid', 'Phone number format -> 254708552140 ');
            return false;
        }
        else {
            return true;
        }

    };

    const getFormErrorMessage = (name: string) => {
        if (name == 'numbers') {
            return errors[name] && <small className="p-error">{errors[name]?.message}</small>
        }
        else if (name == 'message') {
            return errors[name] && <small className="p-error">{errors[name]?.message}</small>
        }
    };

    const getMessagePayload = (numbers: any[], message: string) => {
        let totalMessageNo = 0;

        let data: { message: string; number: any; num: number; tag: string; tag_id: string; }[] = [];

        numbers.map((number: any) => {
            data.push({
                "message": message,
                "number": number,
                "num": messagesNo,
                "tag": "custom",
                "tag_id": "custom"
            });
            totalMessageNo += messagesNo;
        });

        const valueData = {
            data,
            values: totalMessageNo
        }
        return valueData;
    }

    const onSubmit = async (data: any) => {
        const numbers = getValues("numbers");
        const message = getValues("message");

        const valid = validateNumber(numbers);
        if (smsCredit < totalSms) {
            clearMessages();
            showMessage('info', 'Credit', 'Please recharge sms credits to proceed.');
        }
        if (valid && smsCredit >= totalSms) {
            await sendCustomSms(getMessagePayload(numbers, message));
        }
    };

    return (
        <div>
            <div className=' ms-4'>
                <p className='text-md text-gray-900 my-2 '>Enter details below to send sms.</p>
            </div>
            <div className='flex flex-col overflow-hidden bg-white p-3 rounded-md shadow-lg '>
                <div>
                    <Toast ref={toast} />
                    <Messages ref={msgs} />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='formgrid grid '>
                            <div>
                                <span className='text-sm'>Type multiple numbers separated by a comma e.g 254708552140, 254708552140</span>
                            </div>
                            <div className='field col-12 w-full'>
                                <Controller
                                    name="numbers"
                                    control={control}
                                    rules={{
                                        validate: (numbers) => {
                                            if (numbers.length < 1) {
                                                return 'Atleast one phone number is required.'
                                            }

                                            return validateNumber(numbers) ? true : 'Phone number is invalid'
                                        }
                                    }}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.numbers })}></label>
                                            <span className="flex flex-column w-full">
                                                <label className="w-full" htmlFor={field.name}>Phone Number(s)</label>
                                                <Chips
                                                    pt={{
                                                        container: { className: 'w-full' }
                                                    }}
                                                    id={field.name}
                                                    separator=","
                                                    value={field.value}
                                                    onChange={(e) => field.onChange(e.value)}
                                                    className={classNames({ 'p-invalid': fieldState.error })}
                                                />

                                            </span>
                                            {getFormErrorMessage(field.name)}
                                        </>
                                    )}
                                />
                            </div>
                            <div className='field col-12'>
                                <Controller
                                    name="message"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Message are required.'
                                        }
                                    }}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.message })}></label>
                                            <span className="p-float-label">
                                                <InputTextarea
                                                    pt={{
                                                        root: { className: 'w-full' }
                                                    }}
                                                    id={field.name}
                                                    autoResize={true}
                                                    {...field}
                                                    rows={4}
                                                    cols={50}
                                                    className={classNames({ 'p-invalid': fieldState.error })}
                                                />
                                                <label htmlFor={field.name}>Message</label>
                                            </span>
                                            {getFormErrorMessage(field.name)}
                                        </>
                                    )}
                                />
                                <div className='flex justify-content-between'>
                                    <p className='text-xs'>{`${characters} characters`}</p>
                                    <p className='text-xs'>{`${messagesNo} message`}(s)</p>
                                </div>
                            </div>
                        </div>
                        <div className='font-semibold'>Sms Details </div>
                        <div className='grid'>
                            <div className='col-12 grid mt-0 '>
                                <div className='col-3 flex flex-col gap-1 flex-column align-items-center'>
                                    <span className='text-center'>Sms Credits</span>
                                    <Badge value={smsCredit} severity="success"></Badge>
                                </div>
                                <div className='col-3 flex flex-col gap-1 flex-column align-items-center'>
                                    <span className='text-center'>Sms Size</span>
                                    <Badge value={messagesNo} severity="success"></Badge>
                                </div>
                                <div className='col-3 flex flex-col gap-1 flex-column align-items-center'>
                                    <span className='text-center'>Recipients - </span>
                                    <Badge value={recepients} severity="success"></Badge>
                                </div>
                                <div className='col-3 flex flex-col gap-1 flex-column align-items-center'>
                                    <span className='text-center'>Total Sms - </span>
                                    <Badge value={totalSms} severity="success"></Badge>
                                </div>
                            </div>
                        </div>
                        <div className='w-full flex justify-center'>
                            <Button disabled={isLoading} label="Send Messages" type="submit" className='w-full ' />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default CustomSmsForm