import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { classNames } from 'primereact/utils';
import { useState } from "react";
import AppConfig from "../../layout/AppConfig";
import { Controller, useForm } from 'react-hook-form';
import { Message } from "primereact/message";
import { useLoginMutation } from "../../app/services/auth";
import { setCredentials } from "./authSlice";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setIsLoggedInValue } from "../../providers/authproviderSlice";
import logo from '../../assets/img/lounge2darkmain.png';

const LoginPage = () => {
    const [login, { isLoading }] = useLoginMutation();
    const [show, setshow] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const showMessage = () => {
        return (<Message severity="error" text="Incorrect Email or Password" />)
    }
    const defaultValues = {
        email: '',
        password: ''
    };

    const {
        control,
        formState: { errors },
        handleSubmit,
        getValues,
        reset
    } = useForm({ defaultValues });

    const onSubmit = async (values: any) => {
        try {
            const result = await login({ email: values.email, pwd: values.password });
            if ('data' in result) {
                dispatch(setCredentials({
                    roles: result.data?.roles,
                    accessToken: result.data?.accessToken,
                    refreshToken: result.data?.refreshToken
                }));
                dispatch(setIsLoggedInValue(true));
                navigate('/', { replace: false });
                reset();
            } else {
                throw new Error('Incorrect email or password')
            }
        } catch (error: any) {
            setshow(true);
        }
    };

    const getFormErrorMessage = (name: string) => {
        if (name == 'email') {
            return errors['email'] && <small className="p-error">{errors['email'].message}</small>
        }
        else if (name == 'password') {
            return errors['password'] && <small className="p-error">{errors['password'].message}</small>
        }
    };
    return (
        <div className="flex justify-content-center align-items-center h-screen w-screen surface-ground flex-column ">
            {/* <AppConfig /> */}
            {/* <div className="">
                <img src={logo} height={200} alt='Academics Lounge logo'/>
            </div> */}
            <div className="flex flex-column align-items-center justify-content-center w-full px-2">
                <div
                    style={{
                        borderRadius: '56px',
                        padding: '0.3rem',
                        background: 'linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)'
                    }}
                    className="w-full sm:w-fit "
                >
                    <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: '53px' }}>
                        <div className="text-center mb-5">
                            <div className="text-900 text-3xl font-medium mb-3">Welcome back!</div>
                            <span className="text-600 font-medium">Sign in to continue</span>
                        </div>

                        <div>
                            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                                {show ? showMessage() : <></>}
                                <div className=" mt-2">
                                    <span className="">
                                        <Controller
                                            name="email"
                                            control={control}
                                            rules={{ required: 'Email is required.' }}
                                            render={({ field, fieldState }) => (
                                                <>
                                                    <span className="p-input-icon-left">
                                                        <label htmlFor="email1" className="block text-900 text-xl font-medium mb-2">
                                                            Email
                                                        </label>
                                                        <InputText
                                                            id={field.name}
                                                            type="text"
                                                            value={field.value}
                                                            onChange={(e) => field.onChange(e.target.value)}
                                                            placeholder="Email address"
                                                            className={classNames("w-full md:w-30rem mb-2", { 'p-invalid': fieldState.error })}
                                                            style={{ padding: '1rem' }}
                                                        />
                                                    </span>
                                                    {getFormErrorMessage(field.name)}
                                                </>
                                            )}
                                        />
                                    </span>
                                </div>
                                <div className="field mt-2">
                                    <span className="">
                                        <Controller
                                            name="password"
                                            control={control}
                                            rules={{
                                                validate: (data) => {
                                                    return data && data != ' ' ? true : 'Paswword is required'
                                                }
                                            }}
                                            render={({ field, fieldState }) => (
                                                <>
                                                    <span className="p-input-icon-left">
                                                        <label htmlFor="password" className="block text-900 text-xl font-medium mb-2">Password</label>
                                                        <Password
                                                            pt={
                                                                {
                                                                    input: { className: 'w-12' }
                                                                }
                                                            }
                                                            id={field.name}
                                                            {...field}
                                                            toggleMask
                                                            feedback={false}
                                                            className={classNames({ 'p-invalid': fieldState.invalid })}
                                                        />
                                                    </span>
                                                    {getFormErrorMessage(field.name)}
                                                </>
                                            )}
                                        />
                                    </span>
                                </div>
                                <div className="flex align-items-center justify-content-end">
                                    <Link
                                        to={"/forgot"}
                                    >
                                        <a className="font-medium no-underline ml-2 text-right cursor-pointer" style={{ color: 'var(--primary-color)' }}>
                                            Forgot password?
                                        </a>
                                    </Link>

                                </div>
                                <Button disabled={isLoading} label="Sign In" aria-label="Submit" className="mt-5" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginPage