import { mainApi } from "../../../../app/services/main"
const feeChargeApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        getChargedFees: builder.query<any, any>({
            query: (data) => ({
                url: `/fees/charge`,
                method: "GET",
                params: data
            }),
            providesTags: ['feeCharge'],
        }),
        getChargedFeeGroups: builder.query<any, any>({
            query: (data) => ({
                url: `/fees/check`,
                method: "GET",
                params: data
            }),
            providesTags: ['feeCharge'],
        }),
        chargeFee: builder.mutation<any, any>({
            query: (feeData) => ({
                url: '/fees/charge',
                method: 'POST',
                body: feeData,
            }),
            invalidatesTags: ['feeCharge', 'students', 'feeCheck']
        }),
        editChargeFee: builder.mutation<any, any>({
            query: (feeData) => ({
                url: '/fees/charge',
                method: 'PUT',
                body: feeData,
            }),
            invalidatesTags: ['feeCharge', 'students', 'feeCheck']
        }),
        deleteChargeFee: builder.mutation<any, any>({
            query: (feeData) => ({
                url: '/fees/charge/delete',
                method: 'POST',
                body: feeData,
            }),
            invalidatesTags: ['feeCharge', 'students', 'feeCheck']
        })
    }),
    overrideExisting: false,
})

export const {
    useGetChargedFeesQuery,
    useChargeFeeMutation,
    useEditChargeFeeMutation,
    useDeleteChargeFeeMutation
} = feeChargeApi
