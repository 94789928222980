const FormatCurrency = (value: string) => {
  return "KSH " + value;
};

const setDate = (date: string) => {

  const dateArray = date.split("/");

  let tDate = new Date(`${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`);
  return tDate;
}

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const getBalanceSeverity = (balance: number) => {
  if (balance < 0) {
    return 'danger';
  }
  else {
    return 'success';
  }
}

export { FormatCurrency, setDate, getBalanceSeverity, PWD_REGEX };