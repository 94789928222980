import AppMenuitem from './AppMenuitem';
import { MenuProvider } from './context/menucontext';
import { AppMenuItem } from '../types';
import CanAccess from '../components/auth/canaccess';

const AppMenu = () => {

    const model: AppMenuItem[] = [
        {
            action: 'view',
            resource: 'dashboard',
            label: 'Home',
            items: [
                { action: 'view', resource: 'dashboard', label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/' },
                { action: 'view', resource: 'reports', label: 'Reports', icon: 'pi pi-fw pi-chart-bar', to: '/reports' }
            ]
        },
        {
            action: 'view',
            resource: 'users',
            label: 'Users Management',
            items: [
                { action: 'view', resource: 'users', label: 'Overview', icon: 'pi pi-fw pi-users', to: '/users' },
            ]
        },
        {
            action: 'view',
            resource: 'academics',
            label: 'Academics Center',
            items: [
                { action: 'view', resource: 'noview', label: 'Overview', icon: 'pi pi-fw pi-building', to: '/academics' },
                { action: 'view', resource: 'classes', label: 'Classes', icon: 'pi pi-fw pi-building-columns', to: '/academics/classes' },
                { action: 'view', resource: 'students', label: 'Students', icon: 'pi pi-fw pi-graduation-cap', to: '/academics/students' },
                { action: 'view', resource: 'noview', label: 'Teachers', icon: 'pi pi-fw pi-clipboard ', to: '/academics/teachers' },
            ]
        },
        {
            action: 'view',
            resource: 'accounts',
            label: 'Accounts Management',
            icon: 'pi pi-fw pi-briefcase',
            to: '/accounts',
            items: [
                {
                    action: 'view',
                    resource: 'noview',
                    label: 'Overview',
                    icon: 'pi pi-fw pi-wallet',
                },
                {
                    action: 'view',
                    resource: 'accounts',
                    label: 'Fee Management',
                    icon: 'pi pi-fw pi-money-bill',
                    items: [
                        {
                            action: 'view',
                            resource: 'noview',
                            label: 'Overview',
                            icon: 'pi pi-fw pi-id-card ',
                            to: '/accounts'
                        },
                        {
                            action: 'view',
                            resource: 'accounts',
                            label: 'Fee Received',
                            icon: 'pi pi-fw pi-sign-in',
                            to: '/accounts/receive'
                        },
                        {
                            action: 'view',
                            resource: 'accounts',
                            label: 'Fee Charge',
                            icon: 'pi pi-fw pi-sign-out',
                            to: '/accounts/charge'
                        },
                        {
                            action: 'view',
                            resource: 'accounts',
                            label: 'Fee Type',
                            icon: 'pi pi-fw pi-list-check',
                            to: '/accounts/type'
                        }
                    ]
                },
                {
                    action: 'view',
                    resource: 'noviewS',
                    label: 'Term Management',
                    icon: 'pi pi-fw pi-objects-column',
                    to: '/accounts/term'
                },
                {
                    label: 'Payroll Management',
                    icon: 'pi pi-fw pi-address-book',
                    to: '/accounts/payroll'
                },
                {
                    action: 'view',
                    resource: 'accounts',
                    label: 'Transport Management',
                    icon: 'pi pi-fw pi-car',
                    to: '/accounts/transport'
                },
                {
                    label: 'Expense Tracking',
                    icon: 'pi pi-fw pi-credit-card',
                    to: '/accounts/expense'
                },
                {
                    label: 'Inventory Management',
                    icon: 'pi pi-fw pi-list-check',
                    to: '/accounts/inventory'
                },
                {
                    label: 'Mpesa',
                    icon: 'pi pi-fw pi-calculator',
                    to: '/accounts/mpesa'
                }
            ]
        },
        {
            action: 'view',
            resource: 'messaging',
            label: 'Messaging',
            items: [
                { action: 'view', resource: 'messaging', label: 'SMS', icon: 'pi pi-fw pi-comment', to: '/messaging/sms' },
                { action: 'view', resource: 'noview', label: 'Email', icon: 'pi pi-fw pi-envelope', to: '/messaging/email' },
                { action: 'view', resource: 'noview', label: 'WhatsApp', icon: 'pi pi-fw pi-whatsapp', to: '/messaging/whatsapp' },
            ]
        },
        {
            label: 'Library Center',
            items: [
                { label: 'Overview', icon: 'pi pi-fw pi-book', to: '/library' }
            ]
        },


    ];

    return (
        <MenuProvider>
            <ul className="layout-menu">
                {model.map((item, i) => {
                    return !item?.seperator ?
                        <CanAccess resource={item.resource} action={item.action} key={item.label}>
                            <AppMenuitem item={item} root={true} index={i} key={item.label} />
                        </CanAccess> :
                        <CanAccess resource={item.resource} action={item.action} key={item.label}>
                            <li className="menu-separator"></li>
                        </CanAccess>;

                })}
            </ul>
        </MenuProvider>
    );
};

export default AppMenu;
