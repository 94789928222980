import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { MenuItem } from 'primereact/menuitem';
import { Toolbar } from 'primereact/toolbar'
import { useDispatch, useSelector } from 'react-redux';
import React, { useRef } from 'react'
import Sentsmstable from './components/sentsmstable';
import { setCustomDialog } from './smslice';
import { Toast } from 'primereact/toast';
import Customtoast from '../../../components/toast/customtoast';
import { Dialog } from 'primereact/dialog';
import CustomSmsForm from './components/customsmsform';
import { Link } from 'react-router-dom';


const SmsPage = () => {
  const toast = useRef<Toast>(null);


  const dispatch = useDispatch();

  const items: MenuItem[] = [
    {
      label: 'Update',
      icon: 'pi pi-refresh'
    },
    {
      label: 'Delete',
      icon: 'pi pi-times'
    }
  ];

  const startContent = (
    <React.Fragment>
      <span> Sms Overview</span>
    </React.Fragment>
  );

  const endContent = (
    <React.Fragment>
      <Button icon="pi pi-plus" label='Custom Message' size='small' severity='info' className="mr-2" onClick={() => { dispatch(setCustomDialog(true)) }} />
      <Link to={'/messaging/sms/student'}>
        <Button icon="pi pi-graduation-cap" size='small' label='Student Message' className="mr-2" />
      </Link>
    </React.Fragment>
  );

  const visible = useSelector((state: any) => state.smsDialogs.custom);
  const onHideDialog = () => {
    dispatch(setCustomDialog(false));
  }
  return (
    <>
      <Dialog
        visible={visible}
        onHide={onHideDialog}
        header={"Compose Custom Sms"}
        style={{ width: '40rem' }}
        breakpoints={{ '960px': '75vw', '641px': '90vw' }}
      >
        <CustomSmsForm />
      </Dialog>
      <Toolbar start={startContent} end={endContent} />
      <Sentsmstable />
    </>
  )
}

export default SmsPage