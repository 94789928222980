import { useEffect } from "react";
import { useGetFeeGroupsQuery } from "../../fee";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { Controller } from "react-hook-form";

const ChargeFeeDropDown = (props: { control: any, errors: any, getFormError: any, setValue: any, type: number }) => {

    const { data, isLoading } = useGetFeeGroupsQuery(null);

    const groupedItemTemplate = (option: any) => {
        return (
            <div className="flex align-items-center">
                <div>{option.label}</div>
            </div>
        );
    };

    return (
        <div className='field col-12 md:col-6'>
            <Controller
                name="group"
                control={props.control}
                rules={{ required: 'Student, class or route is required.' }}
                render={({ field, fieldState }) => (
                    <>
                        <label htmlFor={field.name} className={classNames({ 'p-error': props.errors.value })}></label>
                        <span className="flex ">
                            <Dropdown
                            loading={isLoading}
                                pt={{
                                    root: { className: 'w-full' }
                                }}
                                filter
                                id={field.name}
                                value={field.value}
                                options={data?.data}
                                optionLabel="label"
                                optionGroupLabel="label"
                                optionGroupChildren="items"
                                optionGroupTemplate={groupedItemTemplate}
                                className={classNames({ 'p-invalid': fieldState.error })}
                                onChange={(e) => field.onChange(e.target.value)}
                                placeholder="Select Group, route or student to charge"
                            />
                        </span>
                        {props.getFormError(field.name)}
                    </>
                )}
            />
        </div>
    )
}

export default ChargeFeeDropDown