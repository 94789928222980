import { useSelector } from "react-redux";
import StudentViewDetails from "../../../academics/students/components/studentviewdetails";
import { TabMenu } from "primereact/tabmenu";
import { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";

const StudentViewTablesLayout = () => {
  const selectedStudentFinance = useSelector((state: any) => state.studentSlice.selectedStudentFinance);
  useEffect(() => {
    setActiveIndex(0)
    return () => {
    }
  }, []);

  const [activeIndex, setActiveIndex] = useState<number>(10);
  const items = [
    {
      to: 'receive',
      label: 'Received Fee',
      icon: 'pi pi-home',
      template: (item: any) => itemRenderer(item, 0)
    },
    {
      to: 'charge',
      label: 'Charged Fee',
      icon: 'pi pi-chart-line',
      template: (item: any) => itemRenderer(item, 1)
    }
  ];

  const itemRenderer = (item: any, itemIndex: number) => (
    <Link
      to={item.to}
    >
      <span className="p-menuitem-link flex align-items-center gap-2" onClick={() => setActiveIndex(itemIndex)}>
        <span className="font-bold">{item.label}</span>
      </span>
    </Link>

  );
  return (
    <div>
      <StudentViewDetails selectedStudent={selectedStudentFinance} fees={true} />
      <div className="w-full card mt-2">
        <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
        <div className="w-full">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default StudentViewTablesLayout