import { mainApi } from "../../../app/services/main";

const studentsApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        getStudents: builder.query<any, any>({
            query: (data) => ({
                url: `/students`,
                method: "GET",
                params: data
            }),
            providesTags: ['students'],
        }),
        newStudent: builder.mutation<any, any>({
            query: (studentData) => ({
                url: '/students',
                method: 'POST',
                body: studentData,
            }),
            invalidatesTags: ['students', 'feeGroups', 'feeCheck']
        }),
        editStudent: builder.mutation<any, any>({
            query: (studentData) => ({
                url: '/students',
                method: 'PUT',
                body: studentData,
            }),
            invalidatesTags: ['students', 'feeGroups',  'feeCheck']
        }),
        deleteStudent: builder.mutation<any, any>({
            query: (studentData) => ({
                url: '/students/delete',
                method: 'POST',
                body: studentData,
            }),
            invalidatesTags: ['students', 'feeGroups',  'feeCheck']
        })
    }),
    overrideExisting: false,
})

export const {
    useGetStudentsQuery, useLazyGetStudentsQuery,
    useNewStudentMutation,
    useEditStudentMutation,
    useDeleteStudentMutation
} = studentsApi
