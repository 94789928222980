import { selectCurrentUser } from "../../features/auth/authSlice";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";

type Props = {
    resource?: string | undefined,
    action?: string | undefined,
    children?: JSX.Element;
};

var currentUser = [1];

const SetUserRole = () => {

    let accessToken = useSelector(selectCurrentUser);
    const decoded: any = jwtDecode(accessToken);

    currentUser = decoded.UserInfo.roles;

    // const token = getTokenFromLocalStorage(TOKEN_KEY);
    // if (token == null)
    //     return
    // const decoded: any = jwtDecode(token);
    // currentUser = decoded.UserInfo.role;
    return;
}

const adminAccess = {
    dashboard: ["show", "list", "view"],
    users: ["list", "create", "show", "edit", "view"],
    students: ["list", "create", "view", "delete", "edit"],
    classes: ["list", "create", "view", "delete", "edit"],
    teachers: ["view"],
    academics: ["view"],
    accounts: ["view", "create", "edit", "delete"],
    messaging: ["view"],
    fee: ["view"]
}

const secretaryAccess = {
    dashboard: ["show", "list", "view"],
    students: ["list", "create", "view", "delete", "edit"],
    classes: ["list", "create", "view", "delete", "edit"],
    teachers: ["view"],
    academics: ["view"],
    accounts: ["view", "create"],
    messaging: ["view"],
    fee: ["view"]
}

const accountsAccess = {
    dashboard: ["show", "list", "view"],
    students: ["list", "view"],
    classes: ["list", "view"],
    teachers: ["view"],
    academics: ["view"],
    accounts: ["view", "create"],
    messaging: ["view"],
    fee: ["view"]
}

const normalUserAccess = {
    dashboard: ["show", "list"],
    fixtures: ["list", "show"]
}
const checkIfCan = (access: any, action: string | undefined, resource: string | undefined) => {

    try {
        if (resource == undefined || action == undefined)
            throw new Error('Resource or action is not defined is not a number!');
        if (access[resource]) {
            const accessArray = access[resource];
            if (accessArray.includes(action)) {
                return true
            }
            else {
                return false
            }
        } else {
            return false
        }
    } catch (error) {
        return false
    }

}

const UserAccess = () => {
    var access = {};
    SetUserRole();

    if (currentUser.includes(7231)) {
        access = { ...access, ...adminAccess };
    }
    else if (currentUser.includes(4819)) {
        access = {...access, ...secretaryAccess};
    } 
    else if(currentUser.includes(6783)){
        access = {...access, ...accountsAccess};
    }
    else {
    }

    return access
}

const CanAccess: React.FC<Props> = ({ resource, action, children }) => {
    const access = UserAccess();
    const can = checkIfCan(access, action, resource);
    return (
        can ?
            <div>
                {children}
            </div> : <></>
    )
}

export default CanAccess