import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { Controller } from "react-hook-form";
import { useGetFeeTypeQuery } from "../../feetype/feetype";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const FeeTypeDropDown = (props: { control: any, errors: any, getFormError: any, setValue: any, type: number }) => {

    const { data, isLoading } = useGetFeeTypeQuery(null);
    const selectedCharged = useSelector((state: any) => state.feeChargeSlice.selectedCharged);
    const visible = useSelector((state: any) => state.feeChargeSlice.editChargeDialog);

    useEffect(() => {
        if (props.type === 1 && !isLoading && data) {
            const feeTypeVal = data.find((dat: { classname: any, _id: any }) => dat._id == selectedCharged.fee_id);
            props.setValue("fee_type", feeTypeVal);            
        }
        return () => {
        }
    }, [visible,isLoading, data]);

    return (
        <div className='field col-12'>
            <Controller
                name="fee_type"
                control={props.control}
                rules={{ required: 'Fee type required.' }}
                render={({ field, fieldState }) => (
                    <>
                        <label htmlFor={field.name} className={classNames({ 'p-error': props.errors.fee_type })}></label>
                        <span className="flex ">
                            <Dropdown
                                pt={{
                                    root: { className: 'w-full' }
                                }}
                                loading={isLoading}
                                id={field.name}
                                value={field.value}
                                className={classNames({ 'p-invalid': fieldState.error })}
                                onChange={(e) => field.onChange(e.target.value)}
                                options={data}
                                optionLabel="fee_name"
                                placeholder="Select fee type"
                            />

                        </span>
                        {props.getFormError(field.name)}
                    </>
                )}
            />
        </div>
    )
}

export default FeeTypeDropDown