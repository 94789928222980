import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '../store'

// const BASE_URL = 'http://localhost:4500'; 
const BASE_URL = 'https://lounge-academic.el.r.appspot.com'; 

export const mainApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).auth.accessToken
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: ['users', 'userDetails', 'auth', 'students', 'feeCheck', 'sms', 'classes', 'feeReceive', 'feeCharge','feeGroups', 'feeType', 'routes', 'viewRoute'],
  endpoints: () => ({
  }),
})
