import { Toast } from 'primereact/toast'
import { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { showToast } from './toastSlice';

const Customtoast = () => {
    const toastDetails = useSelector((state: any) => state.toastSlice);
    const dispatch = useDispatch();

    useEffect(() => {        
        if (toastDetails.show) {
            show();
            dispatch(showToast({ message: '', show: false, severity: 'success', summary:'Success'}));
        }
        return () => {
        }
    }, [toastDetails]);

    const toast = useRef<Toast>(null);
    const show = () => {
        toast.current?.show({ severity: toastDetails.severity, summary: toastDetails.summary, detail: toastDetails.message });
    };
    return (
        <div>
            <Toast ref={toast} />
        </div>
    )
}

export default Customtoast