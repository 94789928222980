import { mainApi } from "../../../app/services/main";

const userApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        getSmsSent : builder.query({
            query: ()=> `/sms`,
            providesTags: ['sms'],
        }),
        sendSms: builder.mutation<any, any>({
            query: (userData) => ({
                url: '/sms',
                method: 'POST',
                body: userData,
            }),
            invalidatesTags: ['sms', 'userDetails']
        })   
    }),
    overrideExisting: false,
  })
  
  export const { useGetSmsSentQuery, useSendSmsMutation } = userApi
