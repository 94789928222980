import { createSlice } from '@reduxjs/toolkit';

const feeTypeSlice = createSlice({
    name: 'feeTypeSlice',
    initialState: { selectedFeeType: {}, newFeeTypeDialog: false, editFeeTypeDialog: false },
    reducers: {
        setFeeType: (state, actions: { payload: any }) => {
            state.selectedFeeType = actions.payload;
        },
        setNewFeeTypeDialog: (state, actions: { payload: boolean }) => {
            state.newFeeTypeDialog = actions.payload;
        },
        setEditFeeTypeDialog: (state, actions: { payload: boolean }) => {
            state.editFeeTypeDialog = actions.payload;
        }
    },
});

export const { setFeeType, setNewFeeTypeDialog, setEditFeeTypeDialog } = feeTypeSlice.actions;

export default feeTypeSlice.reducer;

