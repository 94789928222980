import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { useSelector } from "react-redux";

const StudentFeeReceiveTable = () => {
    const selectedStudentFinance = useSelector((state: any) => state.studentSlice.selectedStudentFinance);

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let _filters = { ...filters };

        // @ts-ignore
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search"> </InputIcon>
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                </IconField>
            </div>
        );
    };

    const header = renderHeader();

    return (
        <>
            <div className="col-12 xl:col-12">
                <div >
                    <h5>Received Fees </h5>
                    <DataTable
                        value={selectedStudentFinance?.feesReceivedArray}
                        size={"small"}
                        paginator
                        showGridlines
                        rows={10}
                        globalFilter={globalFilterValue}
                        dataKey="receipt_no"
                        globalFilterFields={['student_id', 'receipt_no', 'reference_no', 'date_received', 'mode', 'account', 'amount', 'details']}
                        header={header}
                        emptyMessage="No received fees found."
                    >
                        <Column field="receipt_no" header="Receipt" style={{ minWidth: '2rem' }} />
                        <Column field="reference_no" header="Ref No" style={{ minWidth: '8rem' }} />
                        <Column field="date_received" header="Date" style={{ minWidth: '8rem' }} />
                        <Column field="mode" header="Mode" style={{ minWidth: '8rem' }} />
                        <Column field="account" header="Account" style={{ minWidth: '8rem' }} />
                        <Column field="amount" header="Amount" style={{ minWidth: '8rem' }} />
                        <Column field="details" header="Details" style={{ minWidth: '8rem' }} />
                    </DataTable>
                </div>
            </div>
        </>
    )
}

export default StudentFeeReceiveTable