import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'sidebarToogle',
  initialState: { toogle: false },
  reducers: {
    toogleSidebar: (state, actions) => {
        state.toogle = actions.payload
    }
  },
});

export const { toogleSidebar } = slice.actions

export default slice.reducer
