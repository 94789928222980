import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";

import { PrimeReactProvider } from "primereact/api";
import { LayoutProvider } from "./layout/context/layoutcontext";
import './styles/layout/layout.scss';
import { store } from './app/store'
import { Provider } from 'react-redux';

import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

let persistor = persistStore(store);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <PrimeReactProvider>
          <LayoutProvider>
            <App />
          </LayoutProvider>
        </PrimeReactProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
