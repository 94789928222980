import { useRef, useState } from "react";
import { useGetStudentsQuery } from "../students";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { InputIcon } from "primereact/inputicon";
import { IconField } from "primereact/iconfield";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import { setSelectedStudent } from "../studentSlice";
import DeletePopUp from "./deletePopUp";
import { Messages } from "primereact/messages";
import CanAccess from "../../../../components/auth/canaccess";

const StudentsTable = () => {

    const { isLoading, data } = useGetStudentsQuery("1");
    const dispatch = useDispatch();
    const msgs = useRef<Messages>(null);

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const nameBodyTemplate = (rowData: any) => {
        return ` ${rowData.lastname} ${rowData.firstname} ${rowData.othername} `
    };

    const setStudent = (rowData: any) => {
        dispatch(setSelectedStudent(rowData));
    }

    const actionBodyTemplate = (rowData: { index: React.Key | null | undefined; }) => {
        return (
            <React.Fragment>
                <div className='flex justify-content-center'>
                    <CanAccess resource="students" action="view">
                        <Link
                            className='me-1'
                            to={`view`}
                            key={rowData.index}
                        >
                            <div
                                onClick={() => { setStudent(rowData) }}
                                className='edit border border-green-700 rounded-md w-2rem h-2rem flex justify-center align-items-center'
                                data-pr-tooltip="view"
                                data-pr-position="top"
                                style={{ fontSize: '2rem', cursor: 'pointer' }}
                            >
                                <i className="pi pi-eye" style={{ color: 'green' }}></i>
                            </div>
                        </Link>
                    </CanAccess>
                    <CanAccess resource="students" action="edit">
                        <Link
                            className='me-1'
                            to={`edit`}
                            key={rowData.index}
                        >
                            <div
                                onClick={() => { setStudent(rowData) }}
                                className='edit border border-blue-700 rounded-md w-2rem h-2rem flex justify-content-center align-items-center me-1'
                                data-pr-tooltip="view"
                                data-pr-position="top"
                                style={{ fontSize: '2rem', cursor: 'pointer' }}
                            >
                                <i className="pi pi-pencil" style={{ color: 'blue' }}></i>
                            </div>
                        </Link>
                    </CanAccess>
                    <CanAccess resource="students" action="delete">
                        <DeletePopUp rowData={rowData} msgs={msgs} />
                    </CanAccess>
                </div>
            </React.Fragment>
        )
    };

    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let _filters = { ...filters };

        // @ts-ignore
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search"> </InputIcon>
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                </IconField>
                <CanAccess resource="students" action="create">
                    <Link to={"new"}>
                        <Button className='w-full sm:w-fit' type="button" size="small" icon="pi pi-plus" label="New Student" outlined />
                    </Link>
                </CanAccess>

            </div>
        );
    };

    const header = renderHeader();

    return (
        <>
            <div className="col-12 xl:col-12">
                <Messages ref={msgs} />
                <div className="card">
                    <h5>Students Overview</h5>
                    <DataTable
                        value={data}
                        size={"small"}
                        paginator
                        showGridlines
                        rows={10}
                        loading={isLoading}
                        globalFilter={globalFilterValue}
                        dataKey="student_id"
                        globalFilterFields={['student_id', 'lastname', 'firstname', 'othername', 'class_name', 'date_of_admission']}
                        header={header}
                        emptyMessage="No students found."
                    >
                        <Column field="student_id" header="Students ID" filterPlaceholder="Search by Student ID" style={{ minWidth: '8rem' }} />
                        <Column field="firstname" header="Name" body={nameBodyTemplate} filterPlaceholder="Search by Name" style={{ minWidth: '12rem' }} />
                        <Column field="class_name" header="Class" filterPlaceholder="Search by Class Name" style={{ minWidth: '8rem' }} />
                        <Column field="date_of_admission" header="Date of Admission" style={{ minWidth: '8rem' }} />
                        <Column field="action" header="Action" body={actionBodyTemplate} style={{ minWidth: '6rem' }} />
                    </DataTable>
                </div>
            </div>
        </>
    )
}

export default StudentsTable