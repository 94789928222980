import { mainApi } from "../../app/services/main";

const userApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        getUsers : builder.query({
            query: ()=> `/users`,
            providesTags: ['users'],
        }),
        newUsers: builder.mutation<any, any>({
            query: (userData) => ({
                url: '/users',
                method: 'POST',
                body: userData,
            }),
            invalidatesTags: ['users']
        }),
        editUsers: builder.mutation<any, any>({
            query: (userData) => ({
                url: '/users',
                method: 'PUT',
                body: userData,
            }),
            invalidatesTags: ['users']
        }),
        deleteUser: builder.mutation<any, any>({
            query: (userData) => ({
                url: '/users',
                method: 'DELETE',
                body: userData,
            }),
            invalidatesTags: ['users']
        }),
    }),
    overrideExisting: false,
  })
  
  export const { useGetUsersQuery, useNewUsersMutation, useLazyGetUsersQuery, useEditUsersMutation, useDeleteUserMutation } = userApi
