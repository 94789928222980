import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeFromFeeBreakdown, setNewReceivedDialog } from "../feereceiveslice";
import NewPaymentDialog from "./newpaymentdialog";
import NumberBodyTemplate from "../../../../../components/templates/numberbodytemplate";

const FeeBreakDown = () => {

    const [amount, setAmount] = useState(0);
    const dispatch = useDispatch();
    const feePaymentArray = useSelector((state: any) => state.feeReceiveSlice.feeBreakdown);

    const renderHeader = () => {
        return (
            <div className="flex flex-wrap gap-2 align-items-center justify-content-between mt-2">
                <Button className='w-full sm:w-fit' type="button" size="small" severity="success" label={`Total fee - ${amount}`} outlined />
                <Button onClick={() => { dispatch(setNewReceivedDialog(true)) }} className='w-full sm:w-fit' type="button" size="small" icon="pi pi-plus" label="Add payment type" outlined />
            </div>
        );
    };

    const header = renderHeader();

    useEffect(() => {
        let tAmount = 0;
        feePaymentArray.map((fee: any) => {
            tAmount += fee.amount;
        });

        setAmount(tAmount);

        return () => {
        }
    }, [feePaymentArray]);

    const FeeTypeTemplate = (rowData: any, data: { rowIndex: number }) => {
        return (
            <>
                {`${rowData.fee_type.fee_name} `}
            </>
        )
    }

    const actionBodyTemplate = (rowData: any, data: { rowIndex: number }) => {
        return (
            <div
                onClick={(event) => {
                    dispatch(removeFromFeeBreakdown(data.rowIndex));
                }}
                className='trash border border-red-500 rounded-md w-2rem h-2rem flex justify-content-center align-items-center'
                style={{ fontSize: '2rem', cursor: 'pointer' }}
            >
                <i className="pi pi-trash" style={{ color: 'red' }}></i>
            </div>
        )
    };

    return (
        <>
            <NewPaymentDialog />
            <div className="card w-full">
                <h5>Payment Details</h5>
                <DataTable
                    value={feePaymentArray}
                    tableStyle={{ minWidth: '50rem' }}
                    header={header}
                    emptyMessage="No fee data added."
                >
                    <Column field="index" body={NumberBodyTemplate} header="No" style={{ maxWidth: '2rem' }}></Column>
                    <Column field="amount" body={FeeTypeTemplate} header="Fee Type"></Column>
                    <Column field="amount" header="Amount"></Column>
                    <Column field="actions" body={actionBodyTemplate} header="Actions"></Column>
                </DataTable>
            </div>
        </>
    )
}

export default FeeBreakDown