import { createSlice } from '@reduxjs/toolkit';

const feeChargeSlice = createSlice({
    name: 'feeChargeSlice',
    initialState: { selectedCharged: {}, newChargeDialog: false, editChargeDialog: false },
    reducers: {
        setChargedFee: (state, actions: { payload: any }) => {
            state.selectedCharged = actions.payload;
        },
        setNewChargedDialog: (state, actions: { payload: boolean }) => {
            state.newChargeDialog = actions.payload;
        },
        setEditChargedDialog: (state, actions: { payload: boolean }) => {
            state.editChargeDialog = actions.payload;
        }
    },
});

export const { setChargedFee, setNewChargedDialog, setEditChargedDialog } = feeChargeSlice.actions;

export default feeChargeSlice.reducer;

