import { Button } from "primereact/button";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import NumberBodyTemplate from "../../../../components/templates/numberbodytemplate";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ReactToPrint from "react-to-print";
import { Divider } from "primereact/divider";
import AmountBodyTemplate from "../../../../components/templates/amountbodytemplate";

const ReceiptPage = () => {
    const authdetails = useSelector((state: any) => state.auth);
    const printRef = useRef(null);
    const [feeData, setFeeData] = useState([]);
    const selectedReceived = useSelector((state: any) => state.feeReceiveSlice.selectedReceived);

    const FeeTypeTemplate = (rowData: any, data: { rowIndex: number }) => {
        return (
            <>
                {`${rowData.fee_type.fee_name} `}
            </>
        )
    }

    useEffect(() => {
        setFeeData(selectedReceived.payment_data);
        return () => {
        }
    }, [, selectedReceived]);

    const AmountTypeTemplate = (rowData: any) => {
        return <AmountBodyTemplate amount={Number(rowData.amount)} />
    };

    return (
        <>
            <ReactToPrint
                trigger={() => {
                    return <Button icon="pi pi-print" label="Print Receipt" />
                }}
                content={() => printRef.current}
            />
            <div ref={printRef} className="flex align-items-center h-screen" style={{ width: '60rem' }} >
                <div className="w-full border bg-no-repeat bg-center bg-opacity-15 align-items-center h-fit rounded-lg shadow-lg px-6 max-w-md mx-auto text-wrap" style={{ backgroundImage: `url(${authdetails.logo_url})` }}
                >
                    <div className="grid justify-content-between w-full">
                        <div className="col-6 text-gray-700">
                            <h3 className="mb-1 text-blue-500">{authdetails.school_name.toUpperCase()}</h3>
                            <div className="flex">
                                <div className="text-gray-700 mb-2 w-6rem">PO BOX:</div>
                                <div className="font-medium"> {authdetails.po_box} </div>
                            </div>
                            <div className="flex">
                                <div className="text-gray-700 mb-2 w-6rem">Email:</div>
                                <div className="font-medium"> {authdetails.school_email} </div>
                            </div>
                            <div className="flex">
                                <div className="text-gray-700 mb-2 w-6rem">Phone:</div>
                                <div className="font-medium"> {authdetails.school_phone} </div>
                            </div>
                        </div>
                        <div className=" col-6 flex flex-column align-items-end mt-4">
                            <h2 className="mt-4 text-blue-500">Receipt</h2>
                            <div className="flex  flex-column">
                                <div className="flex align-items-center">
                                    <div className="text-gray-700 mb-2 w-10rem">Receipt number:</div>
                                    <div className="font-medium">{`RCT-${selectedReceived.receipt_no}`}</div>
                                </div>
                                <div className="flex">
                                    <div className="text-gray-700 mb-2 w-10rem">Date paid:</div>
                                    <div className="font-medium">{selectedReceived.date_received}</div>
                                </div>
                                <div className="flex">
                                    <div className="text-gray-700 mb-2 w-10rem">Payment mode:</div>
                                    <div className="font-medium">{`${selectedReceived.mode} - ${selectedReceived.reference_no}`}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-4 mt-2">
                        <h2 className="text-lg font-semibold mb-1 ">Students Details</h2>
                        <div className="flex">
                            <div className="text-gray-700 mb-2 w-4rem">Name:</div>
                            <div className="font-medium">{selectedReceived.name.toUpperCase()}</div>
                        </div>
                        <div className="flex">
                            <div className="text-gray-700 mb-2  w-4rem">ID:</div>
                            <div className="font-medium">{selectedReceived.student_id}</div>
                        </div>
                    </div>
                    <div className="mb">
                        <div className="">
                            <h5 className="text-lg font-medium ">Payment Details</h5>
                            <DataTable
                                stripedRows
                                value={feeData}
                                tableStyle={{ minWidth: '10rem' }}
                                emptyMessage="No fee data added."
                            >
                                <Column field="index" body={NumberBodyTemplate} header="No" style={{ maxWidth: '2rem' }}/>
                                <Column field="amount" body={FeeTypeTemplate} header="Description" footer={<div className="flex justify-content-center">Totals</div>}/>
                                <Column field="amount" body={AmountTypeTemplate} header="Amount" style={{ maxWidth: '4rem' }} footer={<AmountBodyTemplate amount={selectedReceived.amount} />}/>
                            </DataTable>
                        </div>
                    </div>
                    <div className="flex flex-column justify-content-center w-full text-center mt-4" >
                        <div className="text-gray-700 mb-2">NB: This is a computer generated receipt, contact school to verify receipt!</div>
                        <div className="text-gray-700 mb-2">{`Motto: ${authdetails.school_motto}`}</div>
                    </div>

                </div>

            </div>
        </>
    )
}

export default ReceiptPage