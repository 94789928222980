import { classNames } from 'primereact/utils';
import { forwardRef, useContext, useEffect, useImperativeHandle, useRef } from 'react';
import { AppTopbarRef } from '../types';
import { LayoutContext } from './context/layoutcontext';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { useLogoutMutation } from '../app/services/auth';
import { useDispatch } from 'react-redux';
import { removeCredentials } from '../features/auth/authSlice';
import Customtoast from '../components/toast/customtoast';
import { showToast } from '../components/toast/toastSlice';

const AppTopbar = forwardRef<AppTopbarRef>((props, ref) => {
    const { layoutState, onMenuToggle, showProfileSidebar } = useContext(LayoutContext);
    const menubuttonRef = useRef(null);
    const topbarmenuRef = useRef(null);
    const topbarmenubuttonRef = useRef(null);

    const dispatch = useDispatch();
    const [logout, { isLoading, data, isError }] = useLogoutMutation();

    useImperativeHandle(ref, () => ({
        menubutton: menubuttonRef.current,
        topbarmenu: topbarmenuRef.current,
        topbarmenubutton: topbarmenubuttonRef.current
    }));

    const accept = () => {
        logout(null);
    }

    const reject = () => {
    }

    useEffect(() => {
        if (!isLoading && data && 'message' in data) {
            dispatch(removeCredentials());
        }
        else if (isError) {
            dispatch(showToast({
                message: 'Error logging out. Try again later!',
                show: true,
                severity: 'warning',
                summary: 'Failed'
            }))
        }
        return () => {
        }
    }, [isLoading, data]);

    const confirm2 = () => {
        confirmDialog({
            message: 'Are you sure you want to log out?',
            header: 'Logout Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept,
            reject
        });
    };

    return (
        <div className="layout-topbar">
            <ConfirmDialog />
            <Customtoast />
            <div className="layout-topbar-logo">
                <span>ACADEMICS LOUNGE</span>
            </div>
            <button ref={menubuttonRef} type="button" className="p-link layout-menu-button layout-topbar-button" onClick={onMenuToggle}>
                <i className="pi pi-bars" />
            </button>

            <button ref={topbarmenubuttonRef} type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={showProfileSidebar}>
                <i className="pi pi-ellipsis-v" />
            </button>

            <div ref={topbarmenuRef} className={classNames('layout-topbar-menu', { 'layout-topbar-menu-mobile-active': layoutState.profileSidebarVisible })}>
                {/* <button type="button" className="p-link layout-topbar-button">
                    <i className="pi pi-calendar"></i>
                    <span>Calendar</span>
                </button> */}
                {/* <Link
                    to='/profile'
                >
                    <button type="button" className="p-link layout-topbar-button">

                        <i className="pi pi-user"></i>
                        <span>Profile</span>
                    </button>
                </Link> */}

                <button type="button" className="p-link layout-topbar-button" onClick={confirm2}>
                    <i className="pi pi-sign-out"></i>
                    <span>Log Out</span>
                </button>
            </div>
        </div>
    );
});

AppTopbar.displayName = 'AppTopbar';

export default AppTopbar;
