import { useEffect } from 'react';
import { useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom';

const IsLoggedIn = () => {
    const isLoggedIn = useSelector((state: any) => state.authProvider.isLoggedIn);
    const navigate = useNavigate();
    useEffect(() => {        
        if (!isLoggedIn) {
            navigate("/login");
        }
        return () => {
        }
    }, [isLoggedIn])

    return (
        <Outlet />
    )
}

export default IsLoggedIn