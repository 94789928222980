import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetOneRouteQuery } from "../viewroute";
import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import React from "react";
import CanAccess from "../../../../components/auth/canaccess";
import DeleteChargedPopUp from "../../feemanagement/charged/components/deletechargedpopup";
import { setChargedFee, setEditChargedDialog, setNewChargedDialog } from "../../feemanagement/charged/feechargeslice";
import { setAddToRouteDialog } from "../transportslice";
import AddtoRouteDialog from "./addtoroutedialog";
import RemoveStudentFromRoutePopUp from "./removestudentfromroutepopup";

const ViewRoutePage = () => {
    const selectedRoute = useSelector((state: any) => state.transportSlice.selectedRoute);
    const { data, isLoading } = useGetOneRouteQuery({ id: selectedRoute._id });
    const dispatch = useDispatch();
    const msgs = useRef<Messages>(null);

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const actionBodyTemplate = (rowData: { index: React.Key | null | undefined; }) => {
        return (
            <React.Fragment>
                <div className='flex justify-content-center'>
                    <CanAccess resource="students" action="delete">
                        <RemoveStudentFromRoutePopUp rowData={rowData} msgs={msgs} />
                    </CanAccess>
                </div>
            </React.Fragment>
        )
    };

    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let _filters = { ...filters };

        // @ts-ignore
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search"> </InputIcon>
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                </IconField>
                <CanAccess resource="accounts" action="create">
                    <Button onClick={() => { dispatch(setAddToRouteDialog(true)) }} className='w-full sm:w-fit' type="button" size="small" icon="pi pi-plus" label="Add Student" outlined />
                </CanAccess>
            </div>
        );
    };

    const nameBodyTemplate = (rowData: any) => {
        return (
            <span>{`${rowData.firstname} ${rowData.lastname} ${rowData.othername}`}</span>
        )
    }

    const header = renderHeader();

    return (
        <>
            <div className="col-12 xl:col-12">
                <AddtoRouteDialog />
                <Messages ref={msgs} />
                <div className="card">
                    <h5>Overview of {selectedRoute?.route_name} route</h5>
                    <DataTable
                        value={data?.data}
                        size={"small"}
                        paginator
                        showGridlines
                        rows={10}
                        loading={isLoading}
                        globalFilter={globalFilterValue}
                        dataKey="_id"
                        globalFilterFields={['student_id', 'firstname', 'lastname', 'othername', 'class_name', 'date_of_admission']}
                        header={header}
                        emptyMessage="No students found in route."
                    >
                        <Column field="student_id" header="Student ID" style={{ minWidth: '2rem' }} />
                        <Column field="firstname" header="Name" body={nameBodyTemplate} style={{ minWidth: '8rem' }} />
                        <Column field="class_name" header="Class" style={{ minWidth: '8rem' }} />
                        <Column field="date_of_admission" header="Admission Date" style={{ minWidth: '8rem' }} />
                        <Column field="action" header="Action" body={actionBodyTemplate} style={{ minWidth: '6rem' }} />
                    </DataTable>
                </div>
            </div>
        </>
    )
}

export default ViewRoutePage