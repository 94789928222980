import { Dropdown } from 'primereact/dropdown'
import { classNames } from 'primereact/utils'
import { Controller } from 'react-hook-form'
import { useGetStudentsQuery } from '../../../../academics/students/students'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'

const StudentsDropdown = (props: { control: any, errors: any, getFormError: any, setValue: any, type: number }) => {
    const { data, isLoading } = useGetStudentsQuery({ tag: 1 });

    const selectedStudent = useSelector((state: any) => state.feeReceiveSlice.selectedReceived);
    useEffect(() => {

        if (props.type == 1 && !isLoading && data) {
            const stuVal = data.find((dat: { student_id: any }) => dat.student_id == selectedStudent.student_id);
            props.setValue("student", stuVal)
        }
        return () => {
        }
    }, [data, isLoading])

    const studentOptionTemplate = (option: any) => {
        return (
            <div className="flex align-items-center">
                <div>{`${option.student_id} - ${option.firstname} ${option.lastname} `}</div>
            </div>
        );
    };

    const selectedStudentTemplate = (option: any, props: any) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <div>{`${option.student_id} - ${option.firstname} ${option.lastname} `}</div>
                </div>
            )
        }
        return <span>{props.placeholder}</span>;
    }

    return (
        <div className='field col-12 md:col-6 w-full'>
            <Controller
                name="student"
                control={props.control}
                rules={{ required: 'Student is required.' }}
                render={({ field, fieldState }) => (
                    <>
                        <label htmlFor={field.name} className={classNames({ 'p-error': props.errors.value })}></label>
                        <span className="flex ">
                            <Dropdown
                                loading={isLoading}
                                pt={{
                                    root: { className: 'w-full' }
                                }}
                                itemTemplate={studentOptionTemplate}
                                id={field.name}

                                value={field.value}
                                className={classNames({ 'p-invalid': fieldState.error })}
                                onChange={(e) => field.onChange(e.target.value)}
                                options={data}
                                valueTemplate={selectedStudentTemplate}
                                placeholder="Select a Student"
                                filterBy='firstname,lastname,student_id'
                                filter
                            />
                        </span>
                        {props.getFormError(field.name)}
                    </>
                )}
            />
        </div>
    )
}

export default StudentsDropdown