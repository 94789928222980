import EditUserDialogs from "./components/edituserdialogs";
import NewUserDialogs from "./components/newuserdialogs";
import UsersTable from "./components/usersTable";

const UsersPage = () => {
  return (
    <>
      <EditUserDialogs />
      <NewUserDialogs />
      <UsersTable />
    </>
  )
}

export default UsersPage;