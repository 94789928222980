import EditFeeTypeDialog from "./components/editfeetypedialog"
import FeeTypeTable from "./components/feetypetable"
import NewFeeTypeDialog from "./components/newfeetypedialog"

const FeeTypePage = () => {
    return (
        <>
            <EditFeeTypeDialog />
            <NewFeeTypeDialog />
            <FeeTypeTable />
        </>
    )
}

export default FeeTypePage