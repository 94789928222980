import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

const userSlice = createSlice({
  name: 'userSlice',
  initialState: { newUserDialog: false, editUserDialog: false, selectedUser: { firstname: '', lastname: '', email: '' } },
  reducers: {
    setNewUserDialog: (state, actions) => {
      state.newUserDialog = actions.payload;
    },
    setEditUserDialog: (state, actions) => {
      state.editUserDialog = actions.payload;
    },
    setSelectedUser: (state, actions) => {
      state.selectedUser = actions.payload
    }
  },
});

export const newUserDialogValue = (state: RootState) => state.userSlice.newUserDialog;
export const editUserDialogValue = (state: RootState) => state.userSlice.editUserDialog;
export const selectedUserValue = (state: RootState) => state.userSlice.selectedUser;

export const { setNewUserDialog, setEditUserDialog, setSelectedUser } = userSlice.actions;
export default userSlice.reducer;

