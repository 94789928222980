import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import { classNames } from "primereact/utils";
import { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import { useSetNewPasswordMutation } from "../../app/services/auth";
import { Password } from "primereact/password";
import { Divider } from "primereact/divider";
import { PWD_REGEX } from "../../utilities/regex";

const NewPasswordPage = () => {
    const params = useParams();

    const [setNewPassword, { isLoading, data }] = useSetNewPasswordMutation();
    const msgs = useRef<Messages>(null);

    const clearMessages = () => {
        msgs.current?.clear();
    };

    useEffect(() => {
      console.log(data);
    
      return () => {
        
      }
    }, [data])
    

    const showMessage = (severity: "error" | "info" | "success" | "warn" | "secondary" | "contrast", summary: string, detail: string) => {
        msgs.current?.show([
            { severity, summary, detail, sticky: true },
        ]);
    }
    const defaultValues = {
        password: '',
        c_password: ''
    };

    const {
        control,
        formState: { errors },
        watch,
        getValues,
        reset,
        handleSubmit
    } = useForm({ defaultValues });

    useEffect(() => {
        let password = getValues("password");

        setuppercase(/[A-Z]/.test(password));
        setlowercase(/[a-z]/.test(password));
        setnumber(/\d/g.test(password));
        setspecialcharacter(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password));
        setminimum(password.length > 6);
        setwhitespaces(/\s/g.test(password))
        return () => {
        }
    }, [watch('password')])

    const onSubmit = async (values: any) => {
        clearMessages();
        try {
            const result = await setNewPassword({ pwd: values.password, email: params.email, code: params.code, id: params.id });
            if ('data' in result) {
                showMessage('success', 'Successful!', `Password has been reset succesfully. Go to login.`);
                reset();
            } else {
                showMessage('error', 'Failed!', `An error has occurred. Try again later!`)
            }
        } catch (error: any) {
            showMessage('error', 'Failed!', `An error has occurred. Try again later!`)
        }
    };

    const getFormErrorMessage = (name: string) => {
        if (name == 'password') {
            return errors['password'] && <small className="p-error">{errors['password'].message}</small>
        }
        else if (name == 'c_password') {
            return errors['c_password'] && <small className="p-error">{errors['c_password'].message}</small>
        }
    };

    const header = <div className="font-bold mb-3">Pick a password</div>;

    const [uppercase, setuppercase] = useState(false);
    const [lowercase, setlowercase] = useState(false);
    const [number, setnumber] = useState(false);
    const [specialcharacter, setspecialcharacter] = useState(false);
    const [minimum, setminimum] = useState(false);
    const [whitespaces, setwhitespaces] = useState(false);

    const footer = (
        <>
            <Divider />
            <p className="mt-2">Requirements</p>
            <ul className="pl-2 ml-2 mt-0 line-height-3">
                <li>
                    <span className="mr-3">At least one uppercase</span>
                    {
                        uppercase ?
                            <span className='pi pi-check' style={{ color: 'green' }}></span>
                            :
                            <span className='pi pi-times' style={{ color: 'red' }}></span>
                    }
                </li>
                <li>
                    <span className="mr-3">At least one lowercase</span>
                    {
                        lowercase ?
                            <span className='pi pi-check' style={{ color: 'green' }}></span>
                            :
                            <span className='pi pi-times' style={{ color: 'red' }}></span>
                    }
                </li>
                <li>
                    <span className="mr-3">At least one number</span>
                    {
                        number ?
                            <span className='pi pi-check' style={{ color: 'green' }}></span>
                            :
                            <span className='pi pi-times' style={{ color: 'red' }}></span>
                    }
                </li>
                <li>
                    <span className="mr-3">At least one special character</span>
                    {
                        specialcharacter ?
                            <span className='pi pi-check' style={{ color: 'green' }}></span>
                            :
                            <span className='pi pi-times' style={{ color: 'red' }}></span>
                    }
                </li>
                <li>
                    <span className="mr-3">Spaces in password</span>
                    {
                        whitespaces ?
                            <span className='pi pi-times' style={{ color: 'red' }}></span>
                            :
                            <span className='pi pi-check' style={{ color: 'green' }}></span>
                    }
                </li>
                <li>
                    <span className="mr-3">Minimum 6 long</span>
                    {
                        minimum ?
                            <span className='pi pi-check' style={{ color: 'green' }}></span>
                            :
                            <span className='pi pi-times' style={{ color: 'red' }}></span>
                    }
                </li>
            </ul>
        </>
    );
    return (
        <div className="flex justify-content-center align-items-center h-screen w-screen surface-ground ">
            <div className="flex flex-column align-items-center justify-content-center w-full px-2">
                <div
                    style={{
                        borderRadius: '56px',
                        padding: '0.3rem',
                        background: 'linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)'
                    }}
                    className="w-full sm:w-fit "
                >
                    <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: '53px' }}>
                        <div className="text-center mb-5">
                            <div className="text-900 text-3xl font-medium mb-3">Set New Password</div>
                            <span className="text-600 font-medium">Enter new password below</span>
                        </div>
                        <div>
                            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                                <Messages ref={msgs} />
                                <div className=" mt-2">
                                    <span className="">
                                        <Controller
                                            name="password"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Password is required.'
                                                },
                                                pattern: {
                                                    value: PWD_REGEX,
                                                    message: "Password format is wrong"
                                                }
                                            }}
                                            render={({ field, fieldState }) => (
                                                <>
                                                    <span className="p-input-icon-left">
                                                        <label htmlFor="password" className="block text-900 text-xl font-medium mb-2">
                                                            Password
                                                        </label>
                                                        <Password
                                                            header={header}
                                                            footer={footer}
                                                            pt={
                                                                {
                                                                    input: { className: 'w-12' }
                                                                }
                                                            }
                                                            id={field.name}
                                                            {...field}
                                                            toggleMask
                                                            className={classNames({ 'p-invalid': fieldState.invalid })}
                                                        />
                                                    </span>
                                                    {getFormErrorMessage(field.name)}
                                                </>
                                            )}
                                        />
                                    </span>
                                </div>
                                <div className="field mt-2">
                                    <span className="">
                                        <Controller
                                            name="c_password"
                                            control={control}
                                            rules={{
                                                validate: () => {
                                                    return (getValues("c_password") == getValues("password")) ? true : "Passwords do not match"
                                                }
                                            }}
                                            render={({ field, fieldState }) => (
                                                <>
                                                    <span className="p-input-icon-left">
                                                        <label htmlFor="password" className="block text-900 text-xl font-medium mb-2">Confirm Password</label>
                                                        <Password
                                                            pt={
                                                                {
                                                                    input: { className: 'w-12' }
                                                                }
                                                            }
                                                            id={field.name}
                                                            {...field}
                                                            toggleMask
                                                            feedback={false}
                                                            className={classNames({ 'p-invalid': fieldState.invalid })}
                                                        />
                                                    </span>
                                                    {getFormErrorMessage(field.name)}
                                                </>
                                            )}
                                        />
                                    </span>
                                </div>
                                <div className="flex align-items-center justify-content-end">
                                    <Link
                                        to={"/login"}
                                    >
                                        <a className="font-medium no-underline ml-2 text-right cursor-pointer" style={{ color: 'var(--primary-color)' }}>
                                            Back to Login
                                        </a>
                                    </Link>
                                </div>
                                <Button disabled={isLoading} label="Reset Password" aria-label="Submit" className="mt-5" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewPasswordPage