import { Button } from "primereact/button";
import { FloatLabel } from "primereact/floatlabel";
import { InputMask } from "primereact/inputmask";
import { classNames } from "primereact/utils";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux"
import { Messages } from "primereact/messages";
import { Divider } from "primereact/divider";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch, InputSwitchChangeEvent } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import ClassesDropDown from "./components/classesdropdown";
import { useEditStudentMutation } from "./students";
import { setDate } from "../../../utilities";

const EditstudentPage = () => {
  const selectedStudent = useSelector((state: any) => state.studentSlice.selectedStudent);

  const [editStudent, { isLoading, data, isError, error }] = useEditStudentMutation();
  const [parent2, setParent2] = useState(false);
  const [parent1, setParent1] = useState(false);

  const msgs = useRef<Messages>(null);
  const showMessage = (severity: "error" | "success" | "info" | "warn" | "secondary" | "contrast", summary: string, detail: string) => {
    clearMessages();
    msgs.current?.show([
      { sticky: true, severity: `${severity}`, summary: `${summary}`, detail: `${detail}` },
    ]);
  }
  const gender = [
    { name: 'Male', code: '1' },
    { name: 'Female', code: '2' }
  ];

  useEffect(() => {
    setParent2(selectedStudent.parents == '2')
    setValue("firstname", selectedStudent.firstname)
    setValue("lastname", selectedStudent.lastname)
    setValue("othername", selectedStudent.othername)
    setValue("dob", setDate(selectedStudent.date_of_birth))
    setValue("details", selectedStudent.details)
    setValue("student_no", selectedStudent.student_id)
    setValue("doa", setDate(selectedStudent.date_of_admission))
    setValue("relationship_1", selectedStudent.guardian_relationship)
    setValue("relationship_1_name", selectedStudent.guardian_name)
    setValue("relationship_1_phone", selectedStudent.guardian_phone)
    setValue("relationship_2", selectedStudent.guardian_relationship_1)
    setValue("relationship_2_name", selectedStudent.guardian_name_1)
    setValue("relationship_2_phone", selectedStudent.guardian_phone_1)

    const genderVal = gender.find((gend) => gend.code == selectedStudent.gender);
    if (genderVal) {
      setValue("gender", genderVal)
    }

    setParent1(true)

    return () => {
    }
  }, [])

  useEffect(() => {

    if (error && !isLoading && 'data' in error) {
      const mess: any = error.data
      clearMessages()
      showMessage('error', 'Failed!', mess.message);
      return
    }

    if (!isLoading && data && 'message' in data) {
      clearMessages()
      showMessage('success', 'Success!', data.message);
      return
    }
    return () => {
    }
  }, [isLoading, data]);


  useEffect(() => {
    if (!parent2) {
      resetField("relationship_2");
      resetField("relationship_2_name");
      resetField("relationship_2_phone");
    }

    return () => {

    }
  }, [parent2])

  const defaultValues = {
    firstname: '',
    lastname: '',
    othername: '',
    dob: new Date(),
    gender: {},
    details: '',
    student_no: '',
    classes: {},
    doa: new Date(),
    relationship_1: '',
    relationship_1_name: '',
    relationship_1_phone: '',
    relationship_2: '',
    relationship_2_name: '',
    relationship_2_phone: ''
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    watch,
    resetField
  } = useForm({ defaultValues });

  const clearMessages = () => {
    msgs.current?.clear();
  };
  useEffect(() => {

    const subscription = watch((value, { name, type }) =>
      clearMessages()
    )
    return () => subscription.unsubscribe();

  }, [watch]);

  const getFormErrorMessage = (
    name:
      'firstname' | 'lastname' | 'othername' | 'dob' | 'gender' | 'details' | 'student_no' | 'classes' |
      'doa' | 'relationship_1' | 'relationship_1_name' | 'relationship_1_phone' | 'relationship_2' | 'relationship_2_name' | 'relationship_2_phone') => {
    return errors[name] && <small className="p-error">{errors[name]?.message}</small>
  };

  const onSubmit = (data: any) => {
    const doa = new Date(data.doa).toLocaleDateString('en-GB');
    const dob = new Date(data.dob).toLocaleDateString('en-GB');
    const payload = {
      "student_id": data.student_no,
      "class_name": data.classes.classname,
      "class_id": data.classes.id,
      "firstname": data.firstname,
      "lastname": data.lastname,
      "othername": data.othername,
      "details": data.details,
      "date_of_birth": dob,
      "date_of_admission": doa,
      "date_of_release": "active",
      "gender": data.gender.code,
      "guardian_relationship": data.relationship_1,
      "guardian_name": data.relationship_1_name,
      "guardian_phone": data.relationship_1_phone,
      "guardian_relationship_1": data.relationship_2,
      "guardian_name_1": data.relationship_2_name,
      "guardian_phone_1": data.relationship_2_phone,
      "status": "1",
      "initiator": "1",
      parents: '2'
    }

    if (data.relationship_2 == '') {
      payload.guardian_relationship_1 = 'none';
      payload.guardian_name_1 = 'none';
      payload.guardian_phone_1 = '0';
      payload.parents = '1';
    }
    editStudent(payload);
  }

  return (
    <div className='card'>
      <Messages ref={msgs} />
      <div className='ms-4'>
        <h2 className='font-bold text-xl text-gray-900'>Edit Student</h2>
        <p className='text-lg text-gray-900 my-2 '>Fill all student details below to edit new student</p>
      </div>
      <div className='flex flex-column overflow-hidden bg-white p-3 rounded-md shadow-lg '>
        <Divider align="left">
          <div className="inline-flex align-items-center">
            <i className="pi pi-user mr-2"></i>
            <b>Student Personal Details</b>
          </div>
        </Divider>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='formgrid grid '>
              <div className='field col-12 md:col-4 ps-3'>
                <Controller
                  name="firstname"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'First Name is required.'
                    },
                    minLength: {
                      value: 3,
                      message: "First Name should be atleast 3 letters"
                    },
                    pattern: {
                      value: /[A-Za-z]{3}/,
                      message: "First Name should only contain letters "
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      <label htmlFor={field.name} className={classNames({ 'p-error': errors.firstname })}></label>
                      <span className="p-float-label">
                        <InputText
                          pt={{
                            root: { className: 'w-full' }
                          }}
                          id={field.name}
                          value={field.value}
                          className={classNames({ 'p-invalid': fieldState.error })}
                          onChange={(e) => field.onChange(e.target.value)}
                        />
                        <label htmlFor={field.name}>First Name</label>
                      </span>
                      {getFormErrorMessage(field.name)}
                    </>
                  )}
                />
              </div>
              <div className='field col-12 md:col-4 ps-3'>
                <Controller
                  name="lastname"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Last Name is required.'
                    },
                    minLength: {
                      value: 3,
                      message: "Last Name should be atleast 3 letters"
                    },
                    pattern: {
                      value: /[A-Za-z]{3}/,
                      message: "Last Name should only contain letters "
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      <label htmlFor={field.name} className={classNames({ 'p-error': errors.lastname })}></label>
                      <span className="p-float-label">
                        <InputText
                          pt={{
                            root: { className: 'w-full' }
                          }}
                          id={field.name}
                          value={field.value}
                          className={classNames({ 'p-invalid': fieldState.error })}
                          onChange={(e) => field.onChange(e.target.value)}
                        />
                        <label htmlFor={field.name}>Surname Name</label>
                      </span>
                      {getFormErrorMessage(field.name)}
                    </>
                  )}
                />
              </div>
              <div className='field col-12 md:col-4 ps-3'>
                <Controller
                  name="othername"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Other Name is required.'
                    },
                    minLength: {
                      value: 3,
                      message: "Other Name should be atleast 3 letters"
                    },
                    pattern: {
                      value: /[A-Za-z]{3}/,
                      message: "Other Name should only contain letters "
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      <label htmlFor={field.name} className={classNames({ 'p-error': errors.othername })}></label>
                      <span className="p-float-label">
                        <InputText
                          pt={{
                            root: { className: 'w-full' }
                          }}
                          id={field.name}
                          value={field.value}
                          className={classNames({ 'p-invalid': fieldState.error })}
                          onChange={(e) => field.onChange(e.target.value)}
                        />
                        <label htmlFor={field.name}>Other Name(s)</label>
                      </span>
                      {getFormErrorMessage(field.name)}
                    </>
                  )}
                />
              </div>
              <div className='field col-12 md:col-4 ps-3'>
                <Controller
                  name="dob"
                  control={control}
                  rules={{ required: 'Date of Birth is required.' }}
                  render={({ field, fieldState }) => (
                    <>
                      <label htmlFor={field.name} className={classNames({ 'p-error': errors.dob })}></label>
                      <span className="p-float-label">
                        <Calendar
                          pt={{ root: { className: 'w-full' } }}
                          dateFormat="dd/mm/yy"
                          id={field.name}
                          value={field.value}
                          className={classNames({ 'p-invalid': fieldState.error })}
                          onChange={(e) => { field.onChange(e.target.value) }}
                          showIcon
                        />
                        <label htmlFor={field.name}>Date of Birth</label>
                      </span>
                      {getFormErrorMessage(field.name)}
                    </>
                  )}
                />
              </div>
              <div className='field col-12 md:col-4 ps-3'>
                <Controller
                  name="gender"
                  control={control}
                  rules={{ required: 'Gender required.' }}
                  render={({ field, fieldState }) => (
                    <>
                      <label htmlFor={field.name} className={classNames({ 'p-error': errors.gender })}></label>
                      <span className="flex ">
                        <Dropdown
                          pt={{
                            root: { className: 'w-full' }
                          }}
                          id={field.name}
                          value={field.value}
                          className={classNames({ 'p-invalid': fieldState.error })}
                          onChange={(e) => field.onChange(e.target.value)}
                          options={gender}
                          optionLabel="name"
                          placeholder="Select a Student Gender"
                        />

                      </span>
                      {getFormErrorMessage(field.name)}
                    </>
                  )}
                />
              </div>
              <div className='field col-12 md:col-4 ps-3'>
                <Controller
                  name="details"
                  control={control}
                  rules={{ required: 'Other details is required.', maxLength: { value: 200, message: "details cannot exceed 20 letters" } }}
                  render={({ field, fieldState }) => (
                    <>
                      <label htmlFor={field.name} className={classNames({ 'p-error': errors.details })}></label>
                      <span className="p-float-label">
                        <InputText
                          pt={{
                            root: { className: 'w-full' }
                          }}
                          id={field.name}
                          value={field.value}
                          className={classNames({ 'p-invalid': fieldState.error })}
                          onChange={(e) => field.onChange(e.target.value)}
                        />
                        <label htmlFor={field.name}>Other Details</label>
                      </span>
                      {getFormErrorMessage(field.name)}
                    </>
                  )}
                />
              </div>
              <div className='field col-12 md:col-4 ps-3'>
                <Controller
                  name="student_no"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Student Unique Number is required.'
                    },
                    maxLength: {
                      value: 15,
                      message: "Student Unique Number should not exceed 15 values"
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      <label htmlFor={field.name} className={classNames({ 'p-error': errors.student_no })}></label>
                      <span className="p-float-label">
                        <InputText
                          disabled
                          pt={{
                            root: { className: 'w-full' }
                          }}
                          id={field.name}
                          value={field.value}
                          className={classNames({ 'p-invalid': fieldState.error })}
                          onChange={(e) => field.onChange(e.target.value)}
                        />
                        <label htmlFor={field.name}>Student Unique Number</label>
                      </span>
                      {getFormErrorMessage(field.name)}
                    </>
                  )}
                />
              </div>
              <div className='field col-12 md:col-4 ps-3'>
                <Controller
                  name="doa"
                  control={control}
                  rules={{ required: 'Date of Admission is required.' }}
                  render={({ field, fieldState }) => (
                    <>
                      <label htmlFor={field.name} className={classNames({ 'p-error': errors.doa })}></label>
                      <span className="p-float-label">
                        <Calendar
                          pt={{ root: { className: 'w-full' } }}
                          id={field.name}
                          dateFormat="dd/mm/yy"
                          value={field.value}
                          onChange={(e) => { field.onChange(e.target.value) }}
                          showIcon
                        />
                        <label htmlFor={field.name}>Date of Admission</label>
                      </span>
                      {getFormErrorMessage(field.name)}
                    </>
                  )}
                />
              </div>
              <div className='field col-12 md:col-4 ps-3'>
                <ClassesDropDown control={control} errors={errors} getFormError={getFormErrorMessage} setValue={setValue} type={1} />
              </div>
              <Divider align="left">
                <div className="inline-flex align-items-center">
                  <i className="pi pi-user mr-2"></i>
                  <b>Parent/Guardian Details</b>
                </div>
              </Divider>
              <div className='field col-12 md:col-4 ps-3'>
                <Controller
                  name="relationship_1"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Relationship value is required.'
                    },
                    minLength: {
                      value: 3,
                      message: "Relationship value should be atleast 3 letters"
                    },
                    pattern: {
                      value: /[A-Za-z]{3}/,
                      message: "Relationship value should only contain letters "
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      <label htmlFor={field.name} className={classNames({ 'p-error': errors.relationship_1 })}></label>
                      <span className="p-float-label">
                        <InputText
                          pt={{
                            root: { className: 'w-full' }
                          }}
                          id={field.name}
                          value={field.value}
                          className={classNames({ 'p-invalid': fieldState.error })}
                          onChange={(e) => field.onChange(e.target.value)}
                        />
                        <label htmlFor={field.name}>Relationship to Student</label>
                      </span>
                      {getFormErrorMessage(field.name)}
                    </>
                  )}
                />
              </div>

              {
                parent1 ? <>
                  <div className='field col-12 md:col-4 ps-3'>
                    <Controller
                      name="relationship_1_name"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Name is required.'
                        },
                        minLength: {
                          value: 3,
                          message: "Name should be atleast 3 letters"
                        },
                        pattern: {
                          value: /[A-Za-z]{3}/,
                          message: "Name should only contain letters "
                        }
                      }}
                      render={({ field, fieldState }) => (
                        <>
                          <label htmlFor={field.name} className={classNames({ 'p-error': errors.relationship_1_name })}></label>
                          <span className="p-float-label">
                            <InputText
                              pt={{
                                root: { className: 'w-full' }
                              }}
                              id={field.name}
                              value={field.value}
                              className={classNames({ 'p-invalid': fieldState.error })}
                              onChange={(e) => field.onChange(e.target.value)}
                            />
                            <label htmlFor={field.name}>Name</label>
                          </span>
                          {getFormErrorMessage(field.name)}
                        </>
                      )}
                    />
                  </div>
                  <div className='field col-12 md:col-4 ps-3'>
                    <Controller
                      name="relationship_1_phone"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Phone Number is required.'
                        }
                      }}
                      render={({ field, fieldState }) => (
                        <>
                          <label htmlFor={field.name} className={classNames({ 'p-error': errors.relationship_1_phone })}></label>
                          <FloatLabel>
                            <InputMask
                              pt={{
                                root: { className: 'w-full' }
                              }}
                              id={field.name}
                              value={field.value}
                              className={classNames({ 'p-invalid': fieldState.error })}
                              onChange={(e) => field.onChange(e.target.value)}
                              mask="(254) 999-999-999"
                            />
                            <label htmlFor={field.name}>Phone Number e.g 708552140</label>
                          </FloatLabel>
                          {getFormErrorMessage(field.name)}
                        </>
                      )}
                    />
                  </div>
                </> : <></>
              }

              <div className='field col-12 w-full mt-2'>
                <div className="flex gap-2">
                  <span className='font-bold' >Add second parent or guardian</span>
                  <InputSwitch checked={parent2} onChange={(e: InputSwitchChangeEvent) => setParent2(e.value)} />
                </div>
              </div>

              {parent2 ?
                <>
                  <div className='field col-12 md:col-4 ps-3'>
                    <Controller
                      name="relationship_2"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Relationship value is required.'
                        },
                        minLength: {
                          value: 3,
                          message: "Relationship value should be atleast 3 letters"
                        },
                        pattern: {
                          value: /[A-Za-z]{3}/,
                          message: "Relationship value should only contain letters "
                        }
                      }}
                      render={({ field, fieldState }) => (
                        <>
                          <label htmlFor={field.name} className={classNames({ 'p-error': errors.relationship_2 })}></label>
                          <span className="p-float-label">
                            <InputText
                              pt={{
                                root: { className: 'w-full' }
                              }}
                              id={field.name}
                              value={field.value}
                              className={classNames({ 'p-invalid': fieldState.error })}
                              onChange={(e) => field.onChange(e.target.value)}
                            />
                            <label htmlFor={field.name}>Relationship to Student</label>
                          </span>
                          {getFormErrorMessage(field.name)}
                        </>
                      )}
                    />
                  </div>
                  <div className='field col-12 md:col-4 ps-3'>
                    <Controller
                      name="relationship_2_name"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Name is required.'
                        },
                        minLength: {
                          value: 3,
                          message: "Name should be atleast 3 letters"
                        },
                        pattern: {
                          value: /[A-Za-z]{3}/,
                          message: "Name should only contain letters "
                        }
                      }}
                      render={({ field, fieldState }) => (
                        <>
                          <label htmlFor={field.name} className={classNames({ 'p-error': errors.relationship_2_name })}></label>
                          <span className="p-float-label">
                            <InputText
                              pt={{
                                root: { className: 'w-full' }
                              }}
                              id={field.name}
                              value={field.value}
                              className={classNames({ 'p-invalid': fieldState.error })}
                              onChange={(e) => field.onChange(e.target.value)}
                            />
                            <label htmlFor={field.name}>Name</label>
                          </span>
                          {getFormErrorMessage(field.name)}
                        </>
                      )}
                    />
                  </div>
                  <div className='field col-12 md:col-4 ps-3'>
                    <Controller
                      name="relationship_2_phone"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Phone Number is required.'
                        }
                      }}
                      render={({ field, fieldState }) => (
                        <>
                          <label htmlFor={field.name} className={classNames({ 'p-error': errors.relationship_2_phone })}></label>
                          <FloatLabel>
                            <InputMask
                              pt={{
                                root: { className: 'w-full' }
                              }}
                              id={field.name}
                              value={field.value}
                              className={classNames({ 'p-invalid': fieldState.error })}
                              onChange={(e) => field.onChange(e.target.value)}
                              mask="(254) 999-999-999"
                            />
                            <label htmlFor={field.name}>Phone Number e.g 708552140</label>
                          </FloatLabel>
                          {getFormErrorMessage(field.name)}
                        </>
                      )}
                    />
                  </div>

                </> :
                <></>
              }
            </div>
            <div className='w-full flex justify-content-center'>
              <Button disabled={isLoading} label="Edit Student" type="submit" className='w-6 ' />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default EditstudentPage