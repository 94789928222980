import { useSelector } from "react-redux";
import StudentViewDetails from "./components/studentviewdetails";

const ViewstudentPage = () => {
  const selectedStudent = useSelector((state: any) => state.studentSlice.selectedStudent);
  return (
    <div>
      <StudentViewDetails selectedStudent={selectedStudent} fees={false} />
    </div>
  )
}

export default ViewstudentPage