import { InputNumber } from 'primereact/inputnumber';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react'
import { FormatCurrency } from '../../utilities';
import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useFeesCheckQuery } from '../../app/services/student';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import { setFilteredStudents } from '../../features/messages/sms/smslice';
import { InputIcon } from 'primereact/inputicon';
import { IconField } from 'primereact/iconfield';
import React from 'react';
import { Link } from 'react-router-dom';
import CanAccess from '../auth/canaccess';
import { setSelectedStudentFinance } from '../../features/academics/students/studentSlice';

const defaultFilters: DataTableFilterMeta = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    student_id: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    class_name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    route: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    balance: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    }
};

const StudentFeeTable = () => {
    const dispatch = useDispatch();
    const filteredStudents = useSelector((state: any) => state.smsDialogs.filteredStudents);
    const [filters, setFilters] = useState<DataTableFilterMeta>(defaultFilters);
    const [globalFilterValue, setGlobalFilterValue] = useState<string>('');
    const clearFilter = () => {
        initFilters();
    };

    const initFilters = () => {
        setFilters(defaultFilters);
        setGlobalFilterValue('');
    };

    const { isLoading, data } = useFeesCheckQuery("1");

    useEffect(() => {
        if (data) {
            dispatch(setFilteredStudents(data?.data));
        }
        return () => {
        }
    }, [data])

    const toast = useRef<Toast>(null);
    const dt = useRef(null);
    const onSearchFilterChange = (data: any) => {
        setGlobalFilterValue(data?.target?.value)
    }

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">{`Selected Students : ${filteredStudents.length}`}</h4>
            <Button className='w-full sm:w-fit' type="button" size="small" icon="pi pi-filter-slash" label="Clear Filters" outlined onClick={clearFilter} />
            <IconField iconPosition="left">
                <InputIcon className="pi pi-search"> </InputIcon>
                <InputText value={globalFilterValue} onChange={onSearchFilterChange} placeholder="Search" />
            </IconField>
        </div>
    );

    const balanceFilterTemplate = (options: { value: number | null | undefined; filterCallback: (arg0: number | null, arg1: any) => void; index: any; }) => {
        return <InputNumber value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} mode="currency" currency="KSH" />;
    };

    const balanceBodyTemplate = (rowData: { balance: string; }) => {
        return FormatCurrency(rowData.balance);
    };
    const actionBodyTemplate = (rowData: { index: React.Key | null | undefined; }) => {
        return (
            <React.Fragment>
                <div className='flex justify-content-center'>
                    <CanAccess resource="accounts" action="view">
                        <Link
                            className='me-1'
                            to={`accounts/view`}
                            key={rowData.index}
                        >
                            <div
                                onClick={() => { dispatch(setSelectedStudentFinance(rowData)); }}
                                className='edit border border-green-700 rounded-md w-2rem h-2rem flex justify-center align-items-center'
                                data-pr-tooltip="view"
                                data-pr-position="top"
                                style={{ fontSize: '2rem', cursor: 'pointer' }}
                            >
                                <i className="pi pi-eye" style={{ color: 'green' }}></i>
                            </div>
                        </Link>
                    </CanAccess>

                </div>
            </React.Fragment>
        )
    };

    return (
        <div className=" bg-green-400 rounded-lg w-full">
            <Toast ref={toast} />

            <DataTable
                ref={dt}
                size={"small"}
                value={data?.data}
                dataKey="student_id"
                paginator
                showGridlines
                loading={isLoading}
                onValueChange={(data1) => {
                    dispatch(setFilteredStudents(data1))
                }}
                rows={10}
                globalFilter={globalFilterValue}
                filters={filters}
                header={header}
            >
                <Column field="student_id" header="Students ID" filter filterPlaceholder="Search by Student ID" style={{ minWidth: '12rem' }} />
                <Column field="parents" header="Parents" style={{ minWidth: '6rem' }} />
                <Column field="name" header="Name" filter filterPlaceholder="Search by name" style={{ minWidth: '12rem' }} />
                <Column field="class_name" header="Class" filter filterPlaceholder="Search by Class Name" style={{ minWidth: '12rem' }} />
                <Column field="route_name" header="Route" filter filterPlaceholder="Search by Route" style={{ minWidth: '12rem' }} />
                <Column header="Balance" filterField="balance" dataType="numeric" style={{ minWidth: '10rem' }} body={balanceBodyTemplate} filter filterElement={balanceFilterTemplate} />
                <Column field="date_of_admission" header="Date of Admission" style={{ minWidth: '12rem' }} />
                {/* <CanAccess action='view' resource='accounts'> */}
                    <Column field="action" header="Action" body={actionBodyTemplate} style={{ minWidth: '6rem' }} />
                {/* </CanAccess> */}
            </DataTable>
        </div >
    )
}

export default StudentFeeTable;