import EditRouteDialog from './components/editroutedialog'
import NewRouteDialog from './components/newroutedialog'
import TransportTable from './components/transporttable'

const TransportPage = () => {
  return (
    <div>
      <EditRouteDialog />
      <NewRouteDialog />
      <TransportTable />
    </div>
  )
}

export default TransportPage