import { createSlice } from '@reduxjs/toolkit';

const smsSlice = createSlice({
  name: 'smsDialogs',
  initialState: { custom : false, student: false, toast: { message:'', show: false, severity: 'success' }, filteredStudents:  new Array() } ,
  reducers: {
    setCustomDialog: ( state, actions : {payload : boolean}) => {     
      state.custom = actions.payload;
    },
    setSmsStudentDialog: ( state, actions : {payload : boolean}) => {     
      state.student = actions.payload;
    },
    setFilteredStudents: ( state, actions : {payload : any}) => {
      state.filteredStudents = actions.payload;
    }
  },
});

export const { setCustomDialog, setSmsStudentDialog, setFilteredStudents } = smsSlice.actions;

export default smsSlice.reducer;

