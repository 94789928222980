import { mainApi } from "./main";

const studentApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        feesCheck : builder.query<any, any>({
            query: (tag)=> `/fees/checkit/${tag}`,
            providesTags: ['feeCheck'],
        }),
    }),
    overrideExisting: false,
  })
  
  export const { useFeesCheckQuery, useLazyFeesCheckQuery } = studentApi
