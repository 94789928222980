import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import { classNames } from "primereact/utils";
import { useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../../../components/toast/toastSlice";
import { useEditRouteMutation } from "../transport";
import { setEditRouteDialog } from "../transportslice";

const EditRouteDialog = () => {
  const dispatch = useDispatch();
  const visible = useSelector((state: any) => state.transportSlice.editRouteDialog);
  const selectedRoute = useSelector((state: any) => state.transportSlice.selectedRoute);
  const [editRoute, { isLoading, data, error, isError }] = useEditRouteMutation();
  const msgs = useRef<Messages>(null);

  const headerElement = (
      <div className="inline-flex align-items-center justify-content-center gap-2">
          <span className="font-bold white-space-nowrap">Edit Route</span>
      </div>
  );

  const defaultValues = {
      routename: '',
      details: ''
  };

  const {
      control,
      formState: { errors },
      handleSubmit,
      reset,
      setValue,
      watch
  } = useForm({ defaultValues });

  useEffect(() => {
      clearMessages();
      return () => {
      }
  }, [watch('routename'), watch('details')])


  const onSubmit = async (values: any) => {
      const payload = {
        'id': selectedRoute._id,
          "route_name": values.routename,
          "details": values.details,
      }
      editRoute(payload);
  };

  useEffect(() => {    
    if('_id' in selectedRoute && visible){
      setValue('routename', selectedRoute.route_name);
      setValue('details', selectedRoute.details);
    }  
    return () => {
    }
  }, [, selectedRoute, visible])
  
  const getFormErrorMessage = (
      name: 'details' | 'routename'
  ) => {
      return errors[name] && <small className="p-error">{errors[name]?.message}</small>
  };

  const clearMessages = () => {
      msgs.current?.clear();
  };

  const showMessage = (severity: "error" | "success" | "info" | "warn" | "secondary" | "contrast", summary: string, detail: string) => {
      clearMessages();
      msgs.current?.show([
          { sticky: true, severity: `${severity}`, summary: `${summary}`, detail: `${detail}` },
      ]);
  }

  useEffect(() => {

      if (error && !isLoading && 'data' in error) {
          const mess: any = error.data
          clearMessages()
          showMessage('error', 'Failed!', mess.message);
          return
      }

      if (!isLoading && data && 'message' in data) {
          dispatch(showToast({ message: data.message, show: true, severity: 'success', summary: 'Success' }))
          reset();
          dispatch(setEditRouteDialog(false));
          return
      }
      return () => {
      }
  }, [isLoading, data]);

  const fee_type = [
      { name: 'Tuition', code: 'Tuition' },
      { name: 'Transport', code: 'Transport' },
      { name: 'Admission', code: 'Cash' },
      { name: 'Other', code: 'Other' },
  ];


  return (
      <Dialog visible={visible} modal header={headerElement} style={{ width: '50rem' }} onHide={() => { dispatch(setEditRouteDialog(false)); reset() }}>
          <div>
              <Messages ref={msgs} />
              <div className='ms-4'>
                  <p className='text-md text-gray-900  '>Enter route details to edit route.</p>
              </div>
              <div className='flex flex-col overflow-hidden bg-white p-3 rounded-md shadow-lg '>
                  <form onSubmit={handleSubmit(onSubmit)}>
                      <div className='formgrid grid '>
                          <div className='field col-12'>
                              <Controller
                                  name="routename"
                                  control={control}
                                  rules={{
                                      required: {
                                          value: true,
                                          message: 'Route Name is required.'
                                      },
                                      minLength: {
                                          value: 3,
                                          message: "Route Name should be atleast 3 letters or numbers"
                                      }
                                  }}
                                  render={({ field, fieldState }) => (
                                      <>
                                          <label htmlFor={field.name} className={classNames({ 'p-error': errors.routename })}></label>
                                          <span className="p-float-label">
                                              <InputText
                                                  pt={{
                                                      root: { className: 'w-full' }
                                                  }}
                                                  id={field.name}
                                                  value={field.value}
                                                  className={classNames({ 'p-invalid': fieldState.error })}
                                                  onChange={(e) => { field.onChange(e.target.value); clearMessages(); }}
                                              />
                                              <label htmlFor={field.name}>Route Name</label>
                                          </span>
                                          {getFormErrorMessage(field.name)}
                                      </>
                                  )}
                              />
                          </div>
                          <div className='field col-12'>
                              <Controller
                                  name="details"
                                  control={control}
                                  rules={{
                                      required: {
                                          value: true,
                                          message: 'Details is required.'
                                      },
                                      minLength: {
                                          value: 3,
                                          message: "Details should be atleast 3 letters"
                                      }
                                  }}
                                  render={({ field, fieldState }) => (
                                      <>
                                          <label htmlFor={field.name} className={classNames({ 'p-error': errors.details })}></label>
                                          <span className="p-float-label">
                                              <InputText
                                                  pt={{
                                                      root: { className: 'w-full' }
                                                  }}
                                                  id={field.name}
                                                  value={field.value}
                                                  className={classNames({ 'p-invalid': fieldState.error })}
                                                  onChange={(e) => { field.onChange(e.target.value); clearMessages(); }}
                                              />
                                              <label htmlFor={field.name}>Route Details</label>
                                          </span>
                                          {getFormErrorMessage(field.name)}
                                      </>
                                  )}
                              />
                          </div>
                      </div>
                      <div className='w-full flex justify-content-center'>
                          <Button label="Edit Route" type="submit" className='w-6 ' loading={isLoading} />
                      </div>
                  </form>
              </div>
          </div>
      </Dialog>
  )
}

export default EditRouteDialog