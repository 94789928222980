import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../features/auth/authSlice";
import { jwtDecode } from "jwt-decode";

const IsAuthorized = (allowedRoles: any) => {
  let accessToken = useSelector(selectCurrentUser);
  const decoded: any = jwtDecode(accessToken);   
  const roles = decoded.UserInfo.roles 

  return roles.find((role: any) => allowedRoles?.includes(role));

}
const AccessControlProvider = (props: { allowedRoles: any }) => {
  const location = useLocation(); 

  return (
    IsAuthorized(props.allowedRoles) ? <Outlet/> : <Navigate to="/unauthorized" state={{from: location}} replace />
  )
}

export default AccessControlProvider
