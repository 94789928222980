import Topbar from './topbar';
import StudentFeeTable from '../../components/tables/studentfeetable';

const AdminOverview = () => {

    return (
        <div className="grid">
            <Topbar />
            <StudentFeeTable />
        </div>
    );
};

export default AdminOverview
