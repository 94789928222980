import { configureStore } from '@reduxjs/toolkit'
import { mainApi } from './services/main';
import authReducer from '../features/auth/authSlice'
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { combineReducers } from '@reduxjs/toolkit';
import sidebarSlice from './features/navigation/sidebarSlice';
import screenSizeSlice from './features/navigation/screenSizeSlice';
import userSlice from '../features/users/userSlice';
import smslice from '../features/messages/sms/smslice';
import studentSlice from '../features/academics/students/studentSlice';
import classSlice from '../features/academics/classes/classSlice';
import toastSlice from '../components/toast/toastSlice';
import feeReceiveSlice from '../features/accounts/feemanagement/received/feereceiveslice';
import feeChargeSlice from '../features/accounts/feemanagement/charged/feechargeslice';
import transportSlice from '../features/accounts/transport/transportslice';
import authproviderSlice from '../providers/authproviderSlice';
import feeTypeSlice from '../features/accounts/feemanagement/feetype/feeTypeSlice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage
};

const reducer = combineReducers({
  [mainApi.reducerPath]: mainApi.reducer,
  auth: authReducer,
  sideToogle: sidebarSlice,
  screenSize: screenSizeSlice,
  newUserDialog: userSlice,
  smsDialogs: smslice,
  studentSlice,
  classSlice,
  toastSlice,
  feeReceiveSlice,
  feeChargeSlice,
  feeTypeSlice,
  transportSlice,
  authProvider: authproviderSlice,
  userSlice
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(mainApi.middleware).concat(),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
