import './App.css';
import { createBrowserRouter, Route, createRoutesFromElements, RouterProvider } from 'react-router-dom';

import NotFoundPage from './features/notfound';
import LoginPage from './features/auth';
import AuthProviders from './providers/AuthProviders';
import DashboardLayout from './layout/DashboardLayout';
import OverviewPage from './features/overview';
import ReportsPage from './features/reports';
import AcademicsOverviewPage from './features/academics/overview';
import StudentsOverviewPage from './features/academics/students';
import NewstudentPage from './features/academics/students/newstudent';
import EditstudentPage from './features/academics/students/editstudent';
import TeachersPage from './features/academics/teachers';
import UsersPage from './features/users';
import AccessControlProvider from './providers/access-control-provider.tsx';
import MessagesOverviewPage from './features/messages/overview';
import SmsPage from './features/messages/sms';
import MailPage from './features/messages/mail';
import WhatsAppPage from './features/messages/whatsapp';
import LibraryPage from './features/library';
import FeeOverviewPage from './features/accounts/feemanagement';
import ChargefeePage from './features/accounts/feemanagement/charged/chargefee';
import ReceivefeePage from './features/accounts/feemanagement/received/receivefee';
import PayrollPage from './features/accounts/payroll';
import TransportPage from './features/accounts/transport';
import ExpensePage from './features/accounts/expense';
import InventoryPage from './features/accounts/inventory';
import MpesaPage from './features/accounts/mpesa';
import ViewstudentPage from './features/academics/students/viewstudentpage';
import StudentSmsPage from './features/messages/sms/student';
import 'primereact/resources/themes/lara-light-blue/theme.css'
import ClassesPage from './features/academics/classes';
import TermManagementPage from './features/accounts/termmanagement';
import ViewRoutePage from './features/accounts/transport/view';
import StudentViewTablesLayout from './features/accounts/feemanagement/components/studentviewtableslayout';
import StudentFeeReceiveTable from './features/accounts/feemanagement/studentfeereceivetable';
import StudentFeeChargeTable from './features/accounts/feemanagement/studentfeechargetable';
import IsLoggedIn from './providers/isloggedin';
import ForgotPasswordPage from './features/auth/forgotpassword';
import NewPasswordPage from './features/auth/newpassword';
import ProfilePage from './features/profile';
import FeeTypePage from './features/accounts/feemanagement/feetype';
import NewReceivePage from './features/accounts/feemanagement/received/newreceive';
import EditReceivePage from './features/accounts/feemanagement/received/editreceive';
import ReceiptPage from './features/accounts/feemanagement/receipt';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/'>
      <Route path="*" element={<NotFoundPage />} />
      <Route path='login' element={<LoginPage />} />
      <Route path='forgot' element={<ForgotPasswordPage />} />
      <Route path='new-password/:email/:id/:code' element={<NewPasswordPage />} />
      <Route path='unauthorized' element={<LoginPage />} />
      <Route element={<AuthProviders />}>
        <Route element={<IsLoggedIn />}>
          <Route element={< DashboardLayout />}>
            <Route index element={<OverviewPage />} />
            <Route path="reports" element={<ReportsPage />} />
            <Route path="profile" element={<ProfilePage />} />
            <Route element={<AccessControlProvider allowedRoles={[7231]} />}>
              <Route path='users' element={<UsersPage />} />
            </Route>
            <Route element={<AccessControlProvider allowedRoles={[7231, 6783, 4819]} />}>
              <Route path="academics" >
                <Route index element={<AcademicsOverviewPage />} />
                <Route path="students">
                  <Route index element={<StudentsOverviewPage />} />
                  <Route path="new" element={<NewstudentPage />} />
                  <Route path="edit" element={<EditstudentPage />} />
                </Route>
                <Route path='teachers' element={<TeachersPage />} />
                <Route path='classes' element={<ClassesPage />} />
              </Route>
            </Route>
            <Route element={<AccessControlProvider allowedRoles={[7231, 6783, 4819]} />}>
              <Route path="messaging" >
                <Route index element={<MessagesOverviewPage />} />
                <Route path='sms' >
                  <Route index element={<SmsPage />} />
                  <Route path='student' element={<StudentSmsPage />} />
                </Route>
                <Route path='email' element={<MailPage />} />
                <Route path='whatsapp' element={<WhatsAppPage />} />
              </Route>
            </Route>
            <Route element={<AccessControlProvider allowedRoles={[7231, 4819]} />}>
              <Route path='library' element={<LibraryPage />} />
            </Route>
            <Route element={<AccessControlProvider allowedRoles={[7231, 4819]} />}>
              <Route path='academics'>
                <Route path='students' >
                  <Route index element={<StudentsOverviewPage />} />
                  <Route path='edit' element={<EditstudentPage />} />
                  <Route path='new' element={<NewstudentPage />} />
                  <Route path='view' element={<ViewstudentPage />} />
                </Route>
              </Route>
            </Route>
            <Route element={<AccessControlProvider allowedRoles={[7231, 6783, 4819]} />}>
              <Route path='accounts'>
                <Route index element={<FeeOverviewPage />} />
                <Route path='view' element={<StudentViewTablesLayout />}>
                  <Route index element={<StudentFeeReceiveTable />} />
                  <Route path='receive' element={<StudentFeeReceiveTable />} />
                  <Route path='charge' element={<StudentFeeChargeTable />} />
                </Route>
                <Route path='charge' element={<ChargefeePage />} />
                <Route path='receive' >
                  <Route index element={<ReceivefeePage />} />
                  <Route path='new' element={<NewReceivePage />} />
                  <Route path='edit' element={<EditReceivePage />} />
                  <Route path='receipt' element={<ReceiptPage />} />
                </Route>
                <Route path='type' element={<FeeTypePage />} />
                <Route path='term' element={<TermManagementPage />} />
                <Route path='payroll' element={<PayrollPage />} />
                <Route path='transport' >
                  <Route index element={<TransportPage />} />
                  <Route path='view' element={<ViewRoutePage />} />
                </Route>
                <Route path='expense' element={<ExpensePage />} />
                <Route path='inventory' element={<InventoryPage />} />
                <Route path='mpesa' element={<MpesaPage />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    </Route>
  )
);

const App = () => {
  return (
    <RouterProvider router={router} />
  );
}

export default App;