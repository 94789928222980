import { createSlice } from '@reduxjs/toolkit';

const classSlice = createSlice({
  name: 'classSlice',
  initialState: { selectedClass : {}, newClassDialog: false , editClassDialog: false},
  reducers: {
    setSelectedClass: ( state, actions : {payload : any}) => {     
      state.selectedClass = actions.payload;
    },
    setNewClassDialog: ( state, actions : {payload : boolean}) => {     
      state.newClassDialog = actions.payload;
    },
    setEditClassDialog: ( state, actions : {payload : boolean}) => {     
      state.editClassDialog = actions.payload;
    }
  },
});

export const { setSelectedClass, setNewClassDialog, setEditClassDialog } = classSlice.actions;

export default classSlice.reducer;

