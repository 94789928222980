import { Dropdown } from 'primereact/dropdown'
import { classNames } from 'primereact/utils'
import React, { useEffect } from 'react'
import { Controller } from 'react-hook-form'
import { useGetClassesQuery } from '../../classes/classes'
import { useSelector } from 'react-redux'

const ClassesDropDown = (props: { control: any, errors: any, getFormError: any, setValue: any, type: number }) => {

    const { isLoading, data } = useGetClassesQuery(null);
    const selectedStudent = useSelector((state: any) => state.studentSlice.selectedStudent);
    useEffect(() => {

        if (props.type === 1 && !isLoading && data && 'data' in data) {
            const classVal = data.data.find((dat: { classname: any, _id: any }) => dat._id == selectedStudent.class_id);
            props.setValue("classes", classVal)
        }
        return () => {
        }
    }, [data, isLoading])

    return (
        <Controller
            name="classes"
            control={props.control}
            rules={{ required: 'Class is required.' }}
            render={({ field, fieldState }) => (
                <>
                    <label htmlFor={field.name} className={classNames({ 'p-error': props.errors.classes })}></label>
                    <span className="flex ">
                        <Dropdown
                            loading={isLoading}
                            pt={{
                                root: { className: 'w-full' }
                            }}
                            id={field.name}
                            value={field.value}
                            className={classNames({ 'p-invalid': fieldState.error })}
                            onChange={(e) => field.onChange(e.target.value)}
                            options={data?.data}
                            optionLabel="classname"
                            placeholder="Select Class"
                        />
                    </span>
                    {props.getFormError(field.name)}
                </>
            )}
        />
    )
}

export default ClassesDropDown