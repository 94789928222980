import { mainApi } from "../../../../app/services/main"

const feeTypeApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        getFeeType: builder.query<any, any>({
            query: (data) => ({
                url: `/fees/type`,
                method: "GET",
                params: data
            }),
            providesTags: ['feeType'],
        }),
        createFeeType: builder.mutation<any, any>({
            query: (feeData) => ({
                url: '/fees/type',
                method: 'POST',
                body: feeData,
            }),
            invalidatesTags: ['feeType']
        }),
        editFeeType: builder.mutation<any, any>({
            query: (feeData) => ({
                url: '/fees/type',
                method: 'PUT',
                body: feeData,
            }),
            invalidatesTags: ['feeType']
        }),
        deleteFeeType: builder.mutation<any, any>({
            query: (feeData) => ({
                url: '/fees/type/delete',
                method: 'POST',
                body: feeData,
            }),
            invalidatesTags: ['feeType']
        })
    }),
    overrideExisting: false,
});

export const {
    useGetFeeTypeQuery,
    useCreateFeeTypeMutation,
    useEditFeeTypeMutation,
    useDeleteFeeTypeMutation
} = feeTypeApi
