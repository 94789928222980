import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { useSelector } from "react-redux";

const StudentFeeChargeTable = () => {
    const selectedStudentFinance = useSelector((state: any) => state.studentSlice.selectedStudentFinance);

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const [globalFilterValue, setGlobalFilterValue] = useState('');


    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let _filters = { ...filters };

        // @ts-ignore
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search"> </InputIcon>
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                </IconField>
             
            </div>
        );
    };

    const header = renderHeader();

    return (
        <>
            <div className="col-12 xl:col-12">
                <div className="">
                    <h5>Chaged Fees</h5>
                    <DataTable
                        value={selectedStudentFinance?.feesChargedArray}
                        size={"small"}
                        paginator
                        showGridlines
                        rows={10}
                        globalFilter={globalFilterValue}
                        dataKey="_id"
                        globalFilterFields={['fee_name', 'amount', 'date_of_charge', 'tag', 'tag_id', 'fee_details']}
                        header={header}
                        emptyMessage="No charged fees found."
                    >
                        <Column field="fee_name" header="Fee Name" style={{ minWidth: '2rem' }} />
                        <Column field="tag_id" header="Tag ID" style={{ minWidth: '8rem' }} />
                        <Column field="tag" header="Tag Detail" style={{ minWidth: '8rem' }} />
                        <Column field="fee_details" header="Details" style={{ minWidth: '8rem' }} />
                        <Column field="amount" header="Amount" style={{ minWidth: '8rem' }} />
                        <Column field="date_of_charge" header="Date Charged" style={{ minWidth: '8rem' }} />
                    </DataTable>
                </div>
            </div>
        </>
    )
}

export default StudentFeeChargeTable