import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Messages } from 'primereact/messages';
import { FilterMatchMode } from 'primereact/api';
import CanAccess from '../../../components/auth/canaccess';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useGetClassesQuery } from './classes';
import NewClassDialog from './components/newclassdialog';
import { setEditClassDialog, setNewClassDialog, setSelectedClass } from './classSlice';
import EditClassDialog from './components/editclassdialog';
import DeleteClassPopUp from './components/deleteClassPopUpC';

const ClassesPage = () => {
  const { isLoading, data } = useGetClassesQuery(null);
  const dispatch = useDispatch();
  const msgs = useRef<Messages>(null);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');

  const nameBodyTemplate = (rowData: any) => {
    return ` ${rowData.lastname} ${rowData.firstname} ${rowData.othername} `
  };

  const setClass = (rowData: any) => {
    dispatch(setSelectedClass(rowData));
  }

  const actionBodyTemplate = (rowData: { index: React.Key | null | undefined; }) => {
    return (
      <React.Fragment>
        <div className='flex justify-content-center'>
          <CanAccess resource="classes" action="edit">
            <div
              onClick={() => { setClass(rowData); dispatch(setEditClassDialog(true)) }}
              className='edit border border-blue-700 rounded-md w-2rem h-2rem flex justify-content-center align-items-center me-1'
              data-pr-tooltip="view"
              data-pr-position="top"
              style={{ fontSize: '2rem', cursor: 'pointer' }}
            >
              <i className="pi pi-pencil" style={{ color: 'blue' }}></i>
            </div>
          </CanAccess>
          <CanAccess resource="classes" action="delete">
            <DeleteClassPopUp rowData={rowData} msgs={msgs} />
          </CanAccess>
        </div>
      </React.Fragment>
    )
  };

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };

    // @ts-ignore
    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
        <IconField iconPosition="left">
          <InputIcon className="pi pi-search"> </InputIcon>
          <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
        </IconField>
        <CanAccess resource="classes" action="create">
          <Button onClick={() => { dispatch(setNewClassDialog(true)) }} className='w-full sm:w-fit' type="button" size="small" icon="pi pi-plus" label="New Class" outlined />
        </CanAccess>

      </div>
    );
  };

  const header = renderHeader();

  return (
    <>
      <div className="col-12 xl:col-12">
        <NewClassDialog />
        <EditClassDialog />
        <Messages ref={msgs} />
        <div className="card">
          <h5>Classes Overview</h5>
          <DataTable
            value={data?.data}
            size={"small"}
            paginator
            showGridlines
            rows={10}
            loading={isLoading}
            globalFilter={globalFilterValue}
            dataKey="classname"
            globalFilterFields={['classname', 'classlevel', 'classdetails', 'students']}
            header={header}
            emptyMessage="No classes found."
          >
            <Column field="classname" header="Class Name" style={{ minWidth: '12rem' }} />
            <Column field="classlevel" header="Level" filterPlaceholder="Search by Class Name" style={{ minWidth: '8rem' }} />
            <Column field="classdetails" header="Details" style={{ minWidth: '8rem' }} />
            <Column field="students" header="Students" style={{ minWidth: '8rem' }} />
            <Column field="action" header="Action" body={actionBodyTemplate} style={{ minWidth: '6rem' }} />
          </DataTable>
        </div>
      </div>
    </>
  )
}

export default ClassesPage