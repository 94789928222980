import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Messages } from 'primereact/messages';
import { classNames } from 'primereact/utils';
import { Controller, useForm } from 'react-hook-form';
import { useCreateFeeTypeMutation } from '../feetype';
import { setNewFeeTypeDialog } from '../feeTypeSlice';
import { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../../../../../components/toast/toastSlice';

const NewFeeTypeDialog = () => {

    const dispatch = useDispatch();
    const visible = useSelector((state: any) => state.feeTypeSlice.newFeeTypeDialog);
    const [newFeeType, { isLoading, data, error, isError }] = useCreateFeeTypeMutation();
    const msgs = useRef<Messages>(null);

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            <span className="font-bold white-space-nowrap">New Fee Type</span>
        </div>
    );

    const defaultValues = {
        details: '',
        fee_name: ''
    };

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        watch
    } = useForm({ defaultValues });

    useEffect(() => {
        clearMessages();
        return () => {
        }
    }, [watch('details')])


    const onSubmit = async (values: any) => {
        const payload = {
            fee_name: values.fee_name,
            details: values.details
        }
        newFeeType(payload);
    };

    const getFormErrorMessage = (
        name: 'details' | 'fee_name'
    ) => {
        return errors[name] && <small className="p-error">{errors[name]?.message}</small>
    };

    const clearMessages = () => {
        msgs.current?.clear();
    };

    const showMessage = (severity: "error" | "success" | "info" | "warn" | "secondary" | "contrast", summary: string, detail: string) => {
        clearMessages();
        msgs.current?.show([
            { sticky: true, severity: `${severity}`, summary: `${summary}`, detail: `${detail}` },
        ]);
    }

    useEffect(() => {

        if (error && !isLoading && 'data' in error) {
            const mess: any = error.data
            clearMessages()
            showMessage('error', 'Failed!', mess.message);
            return
        }

        if (!isLoading && data && 'message' in data) {
            dispatch(showToast({ message: data.message, show: true, severity: 'success', summary: 'Success' }))
            reset();
            dispatch(setNewFeeTypeDialog(false));
            return
        }
        return () => {
        }
    }, [isLoading, data]);

    return (
        <Dialog visible={visible} modal header={headerElement} style={{ width: '40rem' }} onHide={() => { dispatch(setNewFeeTypeDialog(false)); reset() }}>
            <div>
                <Messages ref={msgs} />
                <div className='ms-4'>
                    <p className='text-md text-gray-900  '>Enter fee type details to record.</p>
                </div>
                <div className='flex flex-col overflow-hidden p-3 rounded-md shadow-lg bg-white w-full justify-content-center'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='formgrid grid '>
                            <div className='field col-12 md:col-12'>
                                <Controller
                                    name="fee_name"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Fee name is required.'
                                        }
                                    }}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.fee_name })}></label>
                                            <span className="p-float-label">
                                                <InputText
                                                    pt={{
                                                        root: { className: 'w-full' }
                                                    }}
                                                    id={field.name}
                                                    value={field.value}

                                                    onChange={(e) => field.onChange(e.target.value)}
                                                />
                                                <label htmlFor={field.name}>Fee name</label>
                                            </span>
                                            {getFormErrorMessage(field.name)}
                                        </>
                                    )}
                                />
                            </div>
                            <div className='field col-12 md:col-12'>
                                <Controller
                                    name="details"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Fee type details are required.'
                                        }
                                    }}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.details })}></label>
                                            <span className="p-float-label">
                                                <InputText
                                                    pt={{
                                                        root: { className: 'w-full' }
                                                    }}
                                                    id={field.name}
                                                    value={field.value}

                                                    onChange={(e) => field.onChange(e.target.value)}
                                                />
                                                <label htmlFor={field.name}>Fee type details</label>
                                            </span>
                                            {getFormErrorMessage(field.name)}
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                        
                        <div className='w-full flex justify-content-center'>
                            <Button label="Create New Fee Type" type="submit" className='w-6 ' loading={isLoading} />
                        </div>
                    </form>
                </div>
            </div>
        </Dialog>
    )
}

export default NewFeeTypeDialog