import EditReceiveDialog from "./editreceive"
import NewReceiveDialog from "./newreceive"
import ReceiveTable from "./components/receivetable"

const ReceivefeePage = () => {

  return (
    <div>
      <ReceiveTable />
    </div>
  )
}

export default ReceivefeePage