import { Messages } from "primereact/messages";
import { useGetReceivedFeesQuery } from "../feereceive";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import CanAccess from "../../../../../components/auth/canaccess";
import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import { useRef, useState } from "react";
import { FilterMatchMode } from "primereact/api";
import React from "react";
import { setNewReceivedDialog, setReceivedFee } from "../feereceiveslice";
import DeleteReceivedPopUp from "./deletereceivedpopup";
import { Link } from "react-router-dom";

const ReceiveTable = () => {
    const { isLoading, data } = useGetReceivedFeesQuery(null);
    const dispatch = useDispatch();
    const msgs = useRef<Messages>(null);

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const setReceived = (rowData: any) => {
        dispatch(setReceivedFee(rowData));
    }

    const actionBodyTemplate = (rowData: { index: React.Key | null | undefined; }) => {
        return (
            <React.Fragment>
                <div className='flex justify-content-center'>
                    <CanAccess resource="accounts" action="view">
                        <Link
                            to={'receipt'}
                        >
                            <div
                                onClick={() => { setReceived(rowData) }}
                                className='edit border border-blue-700 rounded-md w-2rem h-2rem flex justify-content-center align-items-center me-1'
                                data-pr-tooltip="view"
                                data-pr-position="top"
                                style={{ fontSize: '2rem', cursor: 'pointer' }}
                            >
                                <i className="pi pi-receipt" style={{ color: 'green' }}></i>
                            </div>
                        </Link>

                    </CanAccess>
                    <CanAccess resource="accounts" action="edit">
                        <Link
                            to={'edit'}
                        >
                            <div
                                onClick={() => { setReceived(rowData) }}
                                className='edit border border-blue-700 rounded-md w-2rem h-2rem flex justify-content-center align-items-center me-1'
                                data-pr-tooltip="view"
                                data-pr-position="top"
                                style={{ fontSize: '2rem', cursor: 'pointer' }}
                            >
                                <i className="pi pi-pencil" style={{ color: 'blue' }}></i>
                            </div>
                        </Link>
                    </CanAccess>
                    <CanAccess resource="accounts" action="delete">
                        <DeleteReceivedPopUp rowData={rowData} msgs={msgs} />
                    </CanAccess>
                </div>
            </React.Fragment>
        )
    };

    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let _filters = { ...filters };

        // @ts-ignore
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search"> </InputIcon>
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                </IconField>
                <CanAccess resource="accounts" action="create">
                    <Link
                        to={'new'}
                    >
                        <Button className='w-full sm:w-fit' type="button" size="small" icon="pi pi-plus" label="Receive Fee" outlined />

                    </Link>
                </CanAccess>
            </div>
        );
    };

    const header = renderHeader();

    return (
        <>
            <div className="col-12 xl:col-12">
                <Messages ref={msgs} />
                <div className="card">
                    <h5>Received Fees Overview</h5>
                    <DataTable
                        value={data?.data}
                        size={"small"}
                        paginator
                        showGridlines
                        rows={10}
                        loading={isLoading}
                        globalFilter={globalFilterValue}
                        dataKey="receipt_no"
                        globalFilterFields={['student_id', 'name', 'receipt_no', 'reference_no', 'date_received', 'mode', 'account', 'amount', 'details']}
                        header={header}
                        emptyMessage="No received fees found."
                    >
                        <Column field="receipt_no" header="Receipt" style={{ minWidth: '2rem' }} />
                        <Column field="reference_no" header="Ref No" style={{ minWidth: '8rem' }} />
                        <Column field="student_id" header="Student ID" style={{ minWidth: '8rem' }} />
                        <Column field="name" header="Name" style={{ minWidth: '8rem' }} />
                        <Column field="date_received" header="Date" style={{ minWidth: '8rem' }} />
                        <Column field="mode" header="Mode" style={{ minWidth: '8rem' }} />
                        <Column field="account" header="Account" style={{ minWidth: '8rem' }} />
                        <Column field="amount" header="Amount" style={{ minWidth: '8rem' }} />
                        <Column field="details" header="Details" style={{ minWidth: '8rem' }} />
                        <Column field="action" header="Action" body={actionBodyTemplate} style={{ minWidth: '6rem' }} />
                    </DataTable>
                </div>
            </div>
        </>
    )
}

export default ReceiveTable